import {
  //eslint-disable-next-line
  AdminPanelSettingsOutlined,
  BugReport,
  ExploreOutlined,
  FileOpenOutlined,
  //eslint-disable-next-line
  HelpOutline,
  LightbulbOutlined,
  LockOutlined,
  NotificationsOutlined,
  //eslint-disable-next-line
  PersonOutline,
  //eslint-disable-next-line
  PrivacyTipOutlined,
  SchoolOutlined,
  ShieldOutlined,
  //eslint-disable-next-line
  Storefront,
  StorefrontOutlined,
} from '@mui/icons-material';
import { Badge, Divider, Drawer, List, ListItem, Stack, Typography } from '@mui/material';
import { useReportId } from 'api';
import useExperiments from 'api/experiments';
import { useFlag } from 'api/feature-flags/provider';
import { getPrivacyEnabledOverride } from 'api/utils';
import LogoOnlyUrl from 'asset/sentinel_logo.png';
import Logo from 'components/Logo';
import PureLink from 'components/PureLink';
import React from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';

import { AppRoutes } from 'config/app-routes';
import { linksStartingWith } from 'utils/component';

import * as Styled from './NavDrawer.styled';

type DrawerProps = React.ComponentProps<typeof Drawer>;

//#region types
type NavDrawerProps = {
  variant?: DrawerProps['variant'] | 'mini';
} & Omit<DrawerProps, 'variant'>;
//#endregion

//#region components
function Item(
  props: {
    to: string;
    icon: React.ReactNode;
    children: React.ReactNode;
    active?: boolean;
    isMini: boolean;
  } & React.ComponentProps<typeof ListItem>
) {
  const { to, icon, active, isMini, children, ...rest } = props;
  const [searchParams] = useSearchParams();
  const classNameObject: any = {};
  if (active === true) classNameObject.className = 'active';
  return (
    <ListItem disablePadding>
      <Styled.ListItemButton
        disableGutters={isMini}
        {...{
          component: NavLink,
          to: {
            pathname: to,
            search: searchParams.toString(),
          },
        }}
        {...classNameObject}
        {...rest}
      >
        <Styled.ListItemIcon
          style={{
            padding: isMini ? 0 : undefined,
            minWidth: isMini ? 'unset' : undefined,
            margin: isMini ? 'auto' : undefined,
          }}
        >
          {icon}
        </Styled.ListItemIcon>
        {!isMini && <Styled.ListItemText>{children}</Styled.ListItemText>}
      </Styled.ListItemButton>
    </ListItem>
  );
}
//#endregion

/**
 * Navigation Drawer Component
 * @param open - whether the drawer is open
 * @param onClose - callback to close the drawer
 * @returns {ReactJSXElement}
 */
export default function NavDrawer(props: NavDrawerProps) {
  const reportId = useReportId();
  const { isExperimentEnabled } = useExperiments();

  const insightsEnabled = useFlag('page.insights');
  const insightsVisible = useFlag('page.insights.visible');

  const { variant: _variant, ...rest } = props;
  const isMini = _variant === 'mini';
  const variant = _variant === 'mini' ? 'permanent' : _variant;

  const imgStyle: React.CSSProperties = isMini
    ? {
        padding: '16px 0',
        boxSizing: 'content-box',
      }
    : {};

  return (
    <Styled.Drawer
      variant={variant}
      {...rest}
      style={{
        width: isMini ? '48px' : undefined,
      }}
    >
      <div>
        <PureLink to={`/?reportId=${new URLSearchParams(window.location.search).get('reportId')}`}>
          {true ? (
            <Logo small={isMini} width="60%" marginX="auto" marginY={24} />
          ) : (
            <Styled.Logo
              src={LogoOnlyUrl}
              height={isMini ? '48px' : undefined}
              width={isMini ? '48px' : undefined}
              style={imgStyle}
            />
          )}
        </PureLink>
        <List>
          <Item isMini={isMini} to="/" active={linksStartingWith(AppRoutes.INSIGHTS)} icon={<LightbulbOutlined />}>
            Insights
          </Item>
          {/*
          <Item isMini={isMini} disabled to="/admin" icon={<AdminPanelSettingsOutlined />}>
            Admin
          </Item>
          <Item isMini={isMini} disabled to="/technologies" icon={<Storefront />}>
            Technologies
          </Item>
          */}
          {insightsVisible && (
            <Item isMini={isMini} disabled={!insightsEnabled} to="/api-dashboard" icon={<LightbulbOutlined />}>
              Insights (Api)
            </Item>
          )}
          {isExperimentEnabled('pages.privacy.visible') && (
            <Item
              isMini={isMini}
              disabled={!isExperimentEnabled('pages.privacy.enabled')}
              to={AppRoutes.PRIVACY}
              icon={
                getPrivacyEnabledOverride(reportId) === undefined ? (
                  <PrivacyTipOutlined />
                ) : (
                  <Badge overlap="circular" badgeContent={<BugReport fontSize="small" />}>
                    <PrivacyTipOutlined />
                  </Badge>
                )
              }
            >
              Privacy
            </Item>
          )}
          {isExperimentEnabled('pages.admin.visible') && (
            <Item
              disabled={!isExperimentEnabled('pages.admin.enabled')}
              isMini={isMini}
              to={AppRoutes.ADMIN}
              icon={<LockOutlined />}
            >
              Admin
            </Item>
          )}
          {isExperimentEnabled('pages.internalAdmin.visible') && (
            <Item
              disabled={!isExperimentEnabled('pages.internalAdmin.enabled')}
              isMini={isMini}
              to={AppRoutes.INTERNAL_ADMIN}
              icon={<ShieldOutlined />}
            >
              Rule Configuration
            </Item>
          )}
          {isExperimentEnabled('pages.technology.visible') && (
            <Item
              disabled={!isExperimentEnabled('pages.technology.enabled')}
              isMini={isMini}
              to={AppRoutes.TECHNOLOGY}
              icon={<StorefrontOutlined />}
            >
              Technology
            </Item>
          )}
          {isExperimentEnabled('pages.notifications.visible') && (
            <Item
              disabled={!isExperimentEnabled('pages.notifications.enabled')}
              isMini={isMini}
              to={AppRoutes.NOTIFICATIONS}
              icon={<NotificationsOutlined />}
            >
              Alerting Settings
            </Item>
          )}
          {isExperimentEnabled('pages.explore.visible') && (
            <Item
              disabled={!isExperimentEnabled('pages.explore.enabled')}
              isMini={isMini}
              to={AppRoutes.explore.index()}
              icon={<ExploreOutlined />}
            >
              Data Exploration
            </Item>
          )}
          {isExperimentEnabled('pages.dataExport.visible') && (
            <Item
              disabled={!isExperimentEnabled('pages.dataExport.enabled')}
              isMini={isMini}
              to={AppRoutes.dataExport.index()}
              icon={<FileOpenOutlined />}
            >
              Data Export
            </Item>
          )}
          <Divider />
          {isExperimentEnabled('pages.knowledgeBase.visible') && (
            <Item
              disabled={!isExperimentEnabled('pages.knowledgeBase.enabled')}
              isMini={isMini}
              to={AppRoutes.knowledgeBase.index()}
              icon={<SchoolOutlined />}
            >
              Knowledge Base
            </Item>
          )}
          {isExperimentEnabled('user.isInternal') && window.localStorage.getItem('showInternalTools') === 'true' && (
            <Item isMini={isMini} to="/internal/tools" icon={<LockOutlined />}>
              Internal Tools
            </Item>
          )}
        </List>
      </div>
      <div>
        {/*
        <List>
          <Item isMini={isMini} to="/help" icon={<HelpOutline />}>
            Help
          </Item>
          <Item isMini={isMini} to="/account" icon={<PersonOutline />}>
            My Account
          </Item>
        </List>
        */}
        {!isMini && (
          <>
            <Divider />
            <Stack padding={2}>
              <Typography variant="caption" textAlign="center" display="inline-block">
                &copy;
                {new Date().getFullYear()}
                &nbsp;
                {'Sentinel Insights'}
              </Typography>
              <Typography variant="caption" textAlign="center" display="inline-block">
                All Rights Reserved
              </Typography>
              {isExperimentEnabled('user.isInternal') && (
                <Typography display="inline-block" textAlign="center" variant="caption">
                  {isExperimentEnabled('flag.isProdWebsite')
                    ? // eslint-disable-next-line sentinelinsights/no-mutations
                      `Prod Release ${(process.env.REACT_APP_DASHBOARD_VERSION ?? '').split('/').pop()}`
                    : isExperimentEnabled('flag.isDevWebsite')
                    ? `Beta Commit ${(process.env.REACT_APP_DASHBOARD_VERSION ?? '').slice(0, 7)}`
                    : isExperimentEnabled('flag.isLocalWebsite')
                    ? `Local Environment`
                    : ''}
                </Typography>
              )}
            </Stack>
          </>
        )}
      </div>
    </Styled.Drawer>
  );
}
