import { EnvironmentId } from 'api/apiTypes';

const BEST_PRACTICE_CUSTOMIZATION_ENDPOINT = '/best-practices-customization';

export const BestPracticeCustomizationRoutes = {
  disableInsight: () => `${BEST_PRACTICE_CUSTOMIZATION_ENDPOINT}/state/disable`,
  enableInsight: () => `${BEST_PRACTICE_CUSTOMIZATION_ENDPOINT}/state/enable`,
  addFeedbackFilter: () => `${BEST_PRACTICE_CUSTOMIZATION_ENDPOINT}/feedback-filters`,
  updateFeedbackFilter: (id: string) => `${BEST_PRACTICE_CUSTOMIZATION_ENDPOINT}/feedback-filters/${id}`,
  deleteFeedbackFilter: (id: string) => `${BEST_PRACTICE_CUSTOMIZATION_ENDPOINT}/feedback-filters/${id}`,
  getFeedbackFilters: (envGuid: EnvironmentId) =>
    `${BEST_PRACTICE_CUSTOMIZATION_ENDPOINT}/feedback-filters/environment/${envGuid}`,
};
