import { CheckBox, CheckBoxOutlineBlank, KeyboardArrowDown } from '@mui/icons-material';
import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { PostfixOperandSchema, PostfixOptions, PreviousStatePredicate } from 'components/PostfixNew/types';
import React, { useEffect } from 'react';

import { PostfixOperandProperty, UnwrapPostfixOptionFunctionTypes } from '../../types';

import { useSelectController } from './index.controller';
import { PostfixSelectProperty, SelectModifier, SelectTabs } from './types';
import { useSelectContext } from '.';

export type OperandSelectOptions = UnwrapPostfixOptionFunctionTypes<PostfixSelectProperty['options']>;

export type OperandSelectProps<T extends PostfixOptions> = {
  currentValue?: unknown;
  property: PostfixOperandProperty;
  onChange: PreviousStatePredicate<PostfixOperandSchema<T>>;
  compact?: boolean;
  options: OperandSelectOptions;
};

export function View<T extends PostfixOptions>(props: OperandSelectProps<T>) {
  const state = useSelectController(props);

  if (!state) return null;

  // should always be true

  const { currentValue, options, menu, selectedOption } = state;
  const {
    hidden,
    topComponent: TopComponent,
    tabs,
    selectOptions,
    bottomComponent: BottomComponent,
    modifierButtons,
  } = options;

  if (hidden) {
    return null;
  }

  return (
    <>
      <Button
        endIcon={<KeyboardArrowDown />}
        onClick={(event) => menu.anchorEl.onChange(event.currentTarget)}
        sx={{
          flexGrow: 0,
          minWidth: 100,
        }}
      >
        {selectedOption ? selectedOption.label : props.property.label}
      </Button>
      <Menu
        anchorEl={menu.anchorEl.current}
        open={Boolean(menu.anchorEl.current)}
        onClose={() => menu.anchorEl.onChange(null)}
        slotProps={{
          paper: {
            sx: {
              minWidth: 300,
              height: 'fit-content',
              overflow: 'hidden',
              display: 'flex',
            },
          },
        }}
        MenuListProps={{
          component: Stack,
          sx: {
            height: 'auto',
            width: '100%',
          },
        }}
      >
        {TopComponent && <TopComponent property={props.property} onChange={props.onChange} value={currentValue} />}
        {tabs && !!tabs.options.length && <SelectTabsGroup tabs={tabs} />}
        <List
          sx={{
            overflow: 'auto',
            minHeight: 0,
          }}
        >
          {selectOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => {
                props.onChange((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    [props.property.name]: option.value,
                  },
                }));
                menu.anchorEl.onChange(null);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </List>
        {BottomComponent && (
          <BottomComponent property={props.property} onChange={props.onChange} value={currentValue} />
        )}
        {!!modifierButtons?.length && <Divider />}
        {modifierButtons?.map((modifier) => (
          <SelectModifierButton key={modifier.property} modifier={modifier} />
        ))}
      </Menu>
    </>
  );
}

function SelectTabsGroup(props: { tabs: SelectTabs }) {
  const { tabs } = props;
  const selectContext = useSelectContext();

  const currentValue = selectContext?.value[tabs.property];

  useEffect(() => {
    if (!selectContext) return;

    if (!currentValue && tabs.options.length > 0) {
      selectContext.onChange((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          [tabs.property]: tabs.options[0].value,
        },
      }));
    }
  }, [currentValue, selectContext, tabs.options, tabs.property]);

  if (!selectContext) return null;

  return (
    <>
      <ToggleButtonGroup
        size="small"
        sx={{
          px: 1,
          pb: 1,
          width: '100%',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '& > *': {
            flexGrow: 1,
          },
        }}
        value={currentValue}
        onChange={(_, newValue) =>
          selectContext.onChange((prev) => ({
            ...prev,
            properties: {
              ...prev.properties,
              [tabs.property]: newValue,
            },
          }))
        }
        exclusive
      >
        {tabs.options.map((tab) => (
          <ToggleButton key={tab.value} value={tab.value}>
            {tab.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Divider />
    </>
  );
}

function SelectModifierButton(props: { modifier: SelectModifier }) {
  const { modifier } = props;

  const selectContext = useSelectContext();

  if (!selectContext) return null;
  return (
    <ListItemButton
      key={modifier.property}
      onClick={() =>
        selectContext.onChange((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            [modifier.property]: !prev.properties[modifier.property as keyof typeof prev.properties],
          },
        }))
      }
    >
      <ListItemIcon>
        {selectContext.value[modifier.property as keyof typeof selectContext.value] ? (
          <CheckBox />
        ) : (
          <CheckBoxOutlineBlank />
        )}
      </ListItemIcon>
      <ListItemText primary={modifier.label} />
    </ListItemButton>
  );
}
