import { Autocomplete, ListItem, ListItemIcon } from '@mui/material';
import { PostfixOperandSchema, PostfixOptions, PreviousStatePredicate } from 'components/PostfixNew/types';
import React from 'react';

import { FilledTextField } from 'pages/data-export/dataExport.styles';

import { PostfixOperandProperty, UnwrapPostfixOptionFunctionTypes } from '../../types';

import { useController } from './index.controller';
import { PostfixAutocompleteProperty } from './types';

export type OperandAutocompleteOptions = UnwrapPostfixOptionFunctionTypes<PostfixAutocompleteProperty['options']>;

export type OperandAutocompleteProps<T extends PostfixOptions> = {
  property: PostfixOperandProperty;
  // value: PostfixOperandSchema<{
  //   properties: PostfixAutocompleteProperty[];
  // }>;
  currentValue?: unknown;
  onChange: PreviousStatePredicate<PostfixOperandSchema<T>>;
  compact?: boolean;
  options: OperandAutocompleteOptions;
};

export function View<T extends PostfixOptions>(props: OperandAutocompleteProps<T>) {
  const state = useController(props);

  if (!state) return null;

  const { value, compact, customInput, hidden, multiple, error } = state;

  if (hidden) return null;

  return (
    <Autocomplete
      multiple={multiple}
      options={value.list}
      sx={{
        minWidth: 400,
      }}
      value={
        multiple
          ? value.current
            ? Array.isArray(value.current)
              ? value.current
              : [value.current]
            : []
          : value.current[0] ?? null
      }
      key={props.property.name}
      freeSolo={customInput?.enabled}
      inputValue={customInput?.current}
      size={compact ? 'small' : 'medium'}
      groupBy={(option) => option.group ?? ''}
      onInputChange={(_, newValue) => customInput?.onChange(newValue)}
      getOptionKey={(option) => (typeof option === 'string' ? option : option.value)}
      getOptionLabel={(option) => (typeof option == 'string' ? option : option.label)}
      renderInput={(params) => (
        <FilledTextField {...params} label={props.property.label} error={error || customInput?.error} />
      )}
      onChange={(_, newValue) => {
        if (typeof newValue === 'string') {
          // ignore, we use onBlur to handle this
        } else {
          value.onChange((prev) => ({
            ...prev,
            properties: {
              ...prev.properties,
              [props.property.name]: Array.isArray(newValue)
                ? newValue.map((x) => (typeof x === 'string' ? x : x.value))
                : newValue?.value,
            },
          }));
        }
      }}
      onBlur={() => {
        if (customInput && customInput.current.length && !customInput?.fromSelection && !multiple) {
          if (customInput.error) {
            value.onChange((prev) => ({
              ...prev,
              properties: {
                ...prev.properties,
                [props.property.name]: null,
              },
            }));
          } else {
            value.onChange((prev) => ({
              ...prev,
              properties: {
                ...prev.properties,
                [props.property.name]:
                  customInput.type === 'number' ? parseFloat(customInput.current) : customInput.current,
              },
            }));
          }
        }
      }}
      renderOption={(props, option, _, ownerState) => (
        <ListItem {...props} key={props.key}>
          {option.icon && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                marginRight: 1,
              }}
            >
              {option.icon}
            </ListItemIcon>
          )}
          {ownerState.getOptionLabel(option)}
        </ListItem>
      )}
      // renderOption={forwardRef<HTMLLIElement | null, any>(function AutocompleteOption(props, ref) {
      //   return <ListItem ref={ref} {...props} />;
      // })}
    />
  );
}
