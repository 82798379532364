import { Star, StarOutline } from '@mui/icons-material';
import { IconButton, ListItemIcon, MenuItem } from '@mui/material';
import { useCurrentUser, useReportId, useReports } from 'api';
import { PartialReport } from 'api/apiTypes';
import useExperiments from 'api/experiments';
import type ReportId from 'api/types/report/id';
import {
  addFavoriteEnvironment,
  isEnvironmentFavorited,
  isErrored,
  removeFavoriteEnvironment,
  withoutError,
} from 'api/utils';
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useEnvironmentSelector() {
  const _userProfile = useCurrentUser();
  const userProfile = withoutError(_userProfile);
  const _reports = useReports();
  const [, setSearchParams] = useSearchParams();

  const { isExperimentEnabled } = useExperiments();

  const reportId = useReportId();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [counter, _refresh] = useState(0);
  const open = !!anchorEl;

  const refreshReports = useCallback(() => _refresh((x) => x + 1), []);

  const onClose = () => setAnchorEl(null);
  const onClick: MouseEventHandler<HTMLElement> = (e) => setAnchorEl(e.currentTarget);

  const onReportClick = useCallback(
    (report: { id: ReportId }) => {
      onClose();
      setSearchParams({
        reportId: report.id,
      });
    },
    [setSearchParams]
  );

  const ready = !!userProfile || isErrored(_userProfile);

  const reportName = useMemo(() => _reports.find((x) => x.id === reportId)?.name ?? '', [reportId, _reports]);

  const reportListsRendered = useMemo(() => {
    const _ = counter;
    return organize(_reports)?.map((report) => ({
      name: report.name,
      onClick: () => onReportClick(report),
      component: (
        <MenuItem
          value={report.name}
          key={report.id}
          onClick={() => onReportClick(report)}
          sx={
            isExperimentEnabled('feature.environmentselector.favorites') || isExperimentEnabled('user.isInternal')
              ? {
                  p: '2px 8px 2px 8px !important',
                }
              : {}
          }
        >
          {(isExperimentEnabled('feature.environmentselector.favorites') || isExperimentEnabled('user.isInternal')) && (
            <ListItemIcon>
              <IconButton
                size="small"
                onClick={(e) => {
                  if (report.isFavorite) removeFavoriteEnvironment(report.name);
                  else addFavoriteEnvironment(report.name);
                  e.stopPropagation();
                  refreshReports();
                }}
              >
                {report.isFavorite ? <Star color="warning" /> : <StarOutline />}
              </IconButton>
            </ListItemIcon>
          )}
          {report.name}
        </MenuItem>
      ),
    }));
  }, [counter, _reports, isExperimentEnabled, onReportClick, refreshReports]);

  const [filter, setFilter] = useState('');

  const { reportLists, selectFirstItem } = useMemo(() => {
    const filteredReports = reportListsRendered.filter((x) => x.name.toLowerCase().includes(filter.toLowerCase()));

    return {
      reportLists: filteredReports.map((x) => x.component),
      selectFirstItem: filteredReports.length > 0 ? filteredReports[0].onClick : null,
    };
  }, [filter, reportListsRendered]);

  useEffect(() => {
    if (!open) {
      setFilter('');
    }
  }, [open]);

  return {
    open,
    ready,
    onClose,
    onClick,
    reportLists,
    anchorEl,
    infoIcon: null,
    selectFirstItem,
    filter: {
      current: filter,
      onChange: setFilter,
    },
    reportName,
    refreshReports,
    onReportClick,
    organization: userProfile?.organization ?? '',
  };
}

// type InfoIcon =
//   | {
//       icon: React.ReactElement;
//       tooltip: React.ReactNode;
//     }
//   | undefined;

type PartialReportWithEnv = PartialReport & {
  env: 'Dev' | 'Prod';
};

type PartialReportsWithEnv = {
  name: string;
  variants: PartialReportWithEnv[];
};

function organize(vendors: PartialReport[]) {
  return vendors
    .map((x) => ({
      ...x,
      isFavorite: isEnvironmentFavorited(x.name),
    }))
    .sort((a, b) => {
      if (a.isFavorite) {
        if (b.isFavorite) {
          return a.name.localeCompare(b.name);
        }
        return -1;
      } else if (b.isFavorite) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  const PROD_REGEX = /^([^\n]*?)(\s(\-\s)?prod)?$/gim;
  const DEV_REGEX = /^([^\n]*?)\s(\-\s)?(dev|development|non-prod|non-production)$/gim;
  const prod: PartialReportWithEnv[] = [];
  const dev: PartialReportWithEnv[] = [];

  vendors.forEach((vendor) => {
    // try dev first, as it is exclusive
    const devMatch = DEV_REGEX.exec(vendor.name);
    DEV_REGEX.lastIndex = 0;
    if (devMatch?.length && devMatch?.length > 1) {
      dev.push({
        ...vendor,
        env: 'Dev',
        name: devMatch[1],
      });
      return;
    }
    const prodMatch = PROD_REGEX.exec(vendor.name);
    PROD_REGEX.lastIndex = 0;
    if (prodMatch?.length && prodMatch?.length > 1) {
      prod.push({
        ...vendor,
        env: 'Prod',
        name: prodMatch[1],
      });
    }
  });

  const final: PartialReportsWithEnv[] = [];

  prod.forEach((vendor) => {
    let i = final.findIndex((f) => f.name === vendor.name);
    if (i < 0) {
      i =
        final.push({
          name: vendor.name,
          variants: [],
        }) - 1;
    }
    final[i].variants.push({
      ...vendor,
      name: 'Prod',
    });
  });

  dev.forEach((vendor) => {
    let i = final.findIndex((f) => f.name === vendor.name);
    if (i < 0) {
      i =
        final.push({
          name: vendor.name,
          variants: [],
        }) - 1;
    }
    final[i].variants.push({
      ...vendor,
      name: 'Dev',
    });
  });

  //return final;
}
