import { PostfixOptions } from 'components/PostfixNew/types';
import { useEffect, useMemo, useState } from 'react';

import { OperandSelectProps } from './index.view';

export function useSelectController<T extends PostfixOptions>(props: OperandSelectProps<T>) {
  const { property, onChange, options, currentValue } = props;

  if (property.inputType !== 'select') {
    return null;
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const selectedOption = useMemo(
    () => options.selectOptions.find((x) => x.value === currentValue),
    [currentValue, options.selectOptions]
  );

  useEffect(() => {
    if (property.required && currentValue === undefined && property.defaultValue !== undefined) {
      onChange((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          [property.name]: property.defaultValue,
        },
      }));
    }
  }, [currentValue, onChange, property.defaultValue, property.name, property.required]);

  return {
    options,
    currentValue,
    selectedOption,
    menu: {
      anchorEl: {
        current: anchorEl,
        onChange: setAnchorEl,
      },
    },
  };
}
