import styled from '@emotion/styled';
import { ArrowDownward, ErrorOutline, SearchOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useFlag } from 'api/feature-flags/provider';
import React, { CSSProperties, useMemo, useState } from 'react';
import { ZodError } from 'zod';

import { MiddlewareError, MiddlewareEvent } from '.';

const Backdrop = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px) saturate(1);
`;

export function ViewErrorBoundary({
  error,
  horizontal,
  size,
  children,
}: {
  error?: unknown;
  horizontal?: boolean;
  size?: 'small' | 'medium';
  children?: React.ReactNode;
}) {
  const isInternal = useFlag('role.internalUser');
  const [open, setOpen] = useState(false);

  if (!error) {
    return <>{children}</>;
  }

  // const testObject = {
  //   one: 1,
  //   two: 'two',
  //   three: [1, '2', false],
  // };
  // const d = z
  //   .object({
  //     one: z.number(),
  //     two: z.string(),
  //     three: z.array(z.number()),
  //   })
  //   .safeParse(testObject);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const events = [
  //   new MiddlewareEvent({
  //     namespace: 'Data Export',
  //     name: 'Dimensions & Metrics',
  //     status: 'processing',
  //     path: 'useDataExportDimensionsAndMetrics()',
  //   }),
  //   new MiddlewareEvent({
  //     method: 'GET',
  //     source: 'network',
  //     namespace: 'Data Export',
  //     name: 'Dimensions & Metrics',
  //     status: 'completed',
  //     path: '/data-export/dimensions-and-metrics',
  //   }),
  //   new MiddlewareEvent({
  //     status: 'failed',
  //     source: 'validate',
  //     namespace: 'Data Export',
  //     name: 'Dimensions & Metrics',
  //     path: 'Zod Schema, ApiDataExportDimensionsAndMetrics',
  //   }),
  // ];

  // // eslint-disable-next-line no-console
  // // console.log(d.error?.issues);
  // const error = new MiddlewareError({
  //   event: events[2],
  //   cause: new Error(),
  // });

  const isMiddlewareError = error instanceof MiddlewareError;

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        height: '100%',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& > *': {
          gridColumn: 1,
          gridRow: 1,
        },
      }}
    >
      {children}
      <Backdrop>
        <Stack alignItems="center" direction={size === 'small' && horizontal ? 'row-reverse' : 'column'}>
          <Stack gap={2} alignItems="center" direction={horizontal ? 'row' : 'column'}>
            <ErrorOutline color="error" fontSize={size !== 'small' ? 'large' : 'small'} />
            <Stack alignItems={horizontal ? 'flex-start' : 'center'}>
              <Typography variant={size !== 'small' ? 'h6' : 'body1'}>
                {size === 'small' ? 'There was an unexpected error' : `Uh-oh! Something's off...`}
              </Typography>
              {size !== 'small' && (
                <Typography variant="body1" color="textSecondary">
                  We hit a snag displaying the insights. Try refreshing the page or come back a bit later. If you
                  continue to see this error, please contact us at support@sentinelinsights.com.
                </Typography>
              )}
            </Stack>
          </Stack>
          {isInternal && (
            <>
              {size !== 'small' ? (
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<SearchOutlined />}
                  sx={{ mt: 1 }}
                  onClick={() => setOpen(true)}
                >
                  Inspect Errors
                </Button>
              ) : (
                <IconButton size="small" onClick={() => setOpen(true)}>
                  <SearchOutlined />
                </IconButton>
              )}
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                  display: 'flex',
                  width: '100vw',
                  height: '100vh',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Card sx={{ flex: '0 1 80vw', maxHeight: '90vh' }}>
                  <CardHeader title="Error Details" />
                  <CardContent sx={{ overflowY: 'auto' }}>
                    {isMiddlewareError ? (
                      <>
                        <EventList event={error.event} />
                        <Typography>Error in previous event:</Typography>
                        <Stack bgcolor="black" color="white" p={1} borderRadius={1}>
                          <Typography variant="h6" color="error" fontFamily="monospace">
                            {error.cause instanceof Error ? error?.cause.name : 'UnknownError'}
                          </Typography>
                          {error.cause instanceof ZodError ? (
                            <>
                              <ObjectWithMessage object={error.event.data} errors={error.cause?.issues} />
                            </>
                          ) : (
                            <Typography fontFamily="monospace">{error.message}</Typography>
                          )}
                        </Stack>

                        {/* <ObjectWithMessage object={testObject} errors={d.error?.issues} /> */}
                      </>
                    ) : error instanceof Error ? (
                      <Stack bgcolor="black" color="white" p={1} borderRadius={1}>
                        <Typography variant="h6" color="error" fontFamily="monospace">
                          {error.cause instanceof Error ? error?.cause.name : 'UnknownError'}
                        </Typography>
                        <Typography fontFamily="monospace">{error?.message}</Typography>
                      </Stack>
                    ) : (
                      <>
                        <Typography variant="h6">
                          This error is not tied to any Error instance or MiddlewareEvent, no further details can be
                          shown.
                        </Typography>
                        <Typography>
                          This means that somewhere in the code, the {'<ViewErrorBoundary />'} component was used
                          without passing an error or event chain.
                        </Typography>
                        <Typography>{`${error}`}</Typography>
                      </>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => setOpen(false)}>
                      Close
                    </Button>
                  </CardActions>
                </Card>
              </Modal>
            </>
          )}
        </Stack>
      </Backdrop>
    </Box>
  );
}

function EventList({ event }: { event: MiddlewareEvent }) {
  const events = useMemo(() => {
    const events: MiddlewareEvent[] = [event];

    let parentEvent = event;

    while (parentEvent.parent) {
      events.push(parentEvent.parent);

      parentEvent = parentEvent.parent;
    }

    // eslint-disable-next-line sentinelinsights/no-mutations
    return events.reverse();
  }, [event]);

  return (
    <List>
      <Divider />
      {events.map((event, i) => [
        <ListItem key={event.id}>
          <ListItemIcon>{event.uiGetIcon()}</ListItemIcon>
          <Stack>
            <Typography variant="caption">{event.id}</Typography>
            <Typography variant="subtitle1">{event.toString()}</Typography>
            <Typography variant="body2" color="textSecondary">
              {event.uiGetAction()}
            </Typography>
          </Stack>
          <ListItemText
            primary={<Typography>{event.uiGetStatus()}</Typography>}
            sx={{
              textAlign: 'right',
              minWidth: 100,
            }}
          />
        </ListItem>,
        <Divider key={i}>
          <ArrowDownward />
        </Divider>,
      ])}
    </List>
  );
}

export function ObjectWithMessage({ object, errors }: { object: any; errors?: ObjectViewerV2Error[] }) {
  const unusedErrors = useMemo(() => {
    const unusedErrors: ObjectViewerV2Error[] = [];
    for (const error of errors ?? []) {
      let value = object;
      for (const key of error.path) {
        value = value[key];
      }

      if (value === undefined) {
        unusedErrors.push(error);
      }
    }
    return unusedErrors;
  }, [errors, object]);

  return (
    <div
      style={{
        padding: 16,
        color: 'white',
        borderRadius: 8,
        background: 'black',
      }}
    >
      <ObjectWithMessageNode object={object} path={[]} errors={errors} />
      {unusedErrors.length > 0 && (
        <>
          <Typography>Fields not included</Typography>
          {unusedErrors.map((x) => (
            <Typography key={x.message}>
              - {x.path}: {x.message}
            </Typography>
          ))}
        </>
      )}
    </div>
  );
}

type ObjectViewerV2Error = {
  path: (string | number)[];
  message: string;
};

function ObjectWithMessageNode({
  object,
  path,
  keyName: _keyName,
  includeComma,
  errors,
}: {
  object: any;
  path: string[];
  keyName?: string;
  errors?: ObjectViewerV2Error[];
  includeComma?: boolean;
}) {
  const keyName = _keyName ? `${_keyName}: ` : '';
  let objectType: string = typeof object;

  if (objectType === 'object' && Array.isArray(object)) {
    objectType = 'array';
  }

  if (object === null) {
    objectType = 'null';
  }

  const isEmpty =
    (objectType === 'array' && object.length === 0) || (objectType === 'object' && Object.keys(object).length === 0);

  const prefix = keyName;
  const suffix = includeComma ? ',' : '';

  const errorList = useMemo(
    () =>
      errors
        ?.filter((x) => x.path.join('.') === path.join('.'))
        .map((x) => (
          <Typography key={x.message} sx={{ color: 'red' }}>
            {x.message}
          </Typography>
        )),
    [errors, path]
  );

  const erroredStyle: CSSProperties = errorList?.length
    ? {
        background: 'rgba(255,0,0,0.3)',
        borderColor: 'red',
        borderWidth: '1px',
        borderStyle: 'solid',
      }
    : {};

  switch (objectType) {
    case 'array':
      return (
        <>
          <div style={erroredStyle}>
            {prefix}
            {'['}
            {!isEmpty && (
              <Stack sx={{ pl: 4 }}>
                {object.map((x: any, i: number) => (
                  <ObjectWithMessageNode
                    key={i}
                    errors={errors}
                    object={x}
                    includeComma={true}
                    path={[...path, i.toString()]}
                  />
                ))}
              </Stack>
            )}
            {']'}
            {suffix}
          </div>
          {errorList}
        </>
      );
    case 'object':
      return (
        <>
          <div style={erroredStyle}>
            {prefix}
            {'{'}
            {!isEmpty && (
              <Stack sx={{ pl: 4 }}>
                {Object.keys(object).map((x, i) => (
                  <ObjectWithMessageNode
                    key={i}
                    keyName={x}
                    errors={errors}
                    object={object[x]}
                    includeComma={true}
                    path={[...path, x]}
                  />
                ))}
              </Stack>
            )}
            {'}'}
            {suffix}
          </div>
          {errorList}
        </>
      );
    case 'string':
      return (
        <>
          <Typography>
            {prefix}
            <span style={{ ...erroredStyle, color: 'orange' }}>
              {'"'}
              {object}
              {'"'}
            </span>
            {suffix}
          </Typography>
          {errorList}
        </>
      );
    case 'number':
      return (
        <>
          <Typography>
            {prefix}
            <span style={{ ...erroredStyle, color: 'yellow' }}>{object}</span>

            {suffix}
          </Typography>
          {errorList}
        </>
      );
    case 'boolean':
      return (
        <>
          <Typography>
            {prefix}
            <span style={{ ...erroredStyle, color: 'lightgreen' }}>{`${object}`}</span>
            {suffix}
          </Typography>
          {errorList}
        </>
      );
    case 'undefined':
      return (
        <>
          <Typography>
            {prefix}
            <span style={{ ...erroredStyle, color: 'red' }}>undefined</span>
            {suffix}
          </Typography>
          {errorList}
        </>
      );
    case 'function':
      return (
        <>
          <Typography>
            {prefix}
            <span style={{ ...erroredStyle, color: 'skyblue' }}>function</span>
            {suffix}
          </Typography>
          {errorList}
        </>
      );
    case 'null':
      return (
        <>
          <Typography>
            {prefix}
            <span style={{ ...erroredStyle, color: 'red' }}>null</span>
            {suffix}
          </Typography>
          {errorList}
        </>
      );
  }

  return null;
}
