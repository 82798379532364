import { PostfixOperandSchema, PostfixOptions, PreviousStatePredicate } from 'components/PostfixNew/types';
import React, { useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';

import { OperandRegexOptions, View } from './index.view';
import { PostfixRegexProperty } from './types';

export function OperandRegex<T extends PostfixOptions>(props: {
  property: PostfixRegexProperty;
  value: PostfixOperandSchema<{
    properties: PostfixRegexProperty[];
  }>;
  onChange: PreviousStatePredicate<PostfixOperandSchema<T>>;
  compact?: boolean;
}) {
  const { property, value } = props;

  const previousOptions = useRef<OperandRegexOptions | null>(null);
  const previousValue = useRef<unknown>(null);
  const previousModifierValue = useRef<unknown>(null);

  const options = useMemo(() => {
    const newOptions: OperandRegexOptions = {
      modifierProperty: property.options.modifierProperty(value),
      hidden: property.options.hidden?.(value) ?? false,
    };

    if (!isEqual(previousOptions.current, newOptions)) {
      previousOptions.current = newOptions;
    }

    return previousOptions.current ?? newOptions;
  }, [property.options, value]);

  const currentValue = useMemo(() => {
    const newValue = value.properties[property.name];

    if (!isEqual(previousValue.current, newValue)) {
      previousValue.current = newValue;
    }

    return previousValue.current;
  }, [property.name, value.properties]);

  const modifierValue = useMemo(() => {
    const newValue = value.properties[options.modifierProperty];

    if (!isEqual(previousModifierValue.current, newValue)) {
      previousModifierValue.current = newValue;
    }

    return previousModifierValue.current;
  }, [options.modifierProperty, value.properties]);

  const view = useMemo(
    () => (
      <View
        property={property}
        currentValue={currentValue}
        onChange={props.onChange}
        compact={props.compact}
        options={options}
        modifierValue={modifierValue}
      />
    ),
    [currentValue, modifierValue, options, property, props.compact, props.onChange]
  );

  if (options.hidden) return null;

  return view;
}
