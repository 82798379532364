import { ArrowDropDown } from '@mui/icons-material';
import { Divider, ListItem, ListItemText, Menu, TextField, Typography } from '@mui/material';
import React from 'react';

import EnvironmentSelectorSkeleton from './EnvironmentSelector.skeleton';
import * as Styled from './EnvironmentSelector.styles';
import useEnvironmentSelector from './useEnvironmentSelector';

type EnvironmentSelectorProps = {
  useNativeDropdown?: boolean;
};

export default function EnvironmentSelector(props: EnvironmentSelectorProps) {
  const { useNativeDropdown = false } = props;
  const { open, ready, reportLists, onClose, onClick, anchorEl, reportName, organization, filter, selectFirstItem } =
    useEnvironmentSelector();

  if (!ready) return <EnvironmentSelectorSkeleton />;

  if (useNativeDropdown)
    return (
      <TextField
        fullWidth
        select
        label="Environment Selector"
        value={reportName}
        sx={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '.MuiListItemIcon-root': {
            display: 'none',
          },
        }}
      >
        {reportLists}
      </TextField>
    );

  return (
    <>
      <Styled.ListItemButton onClick={onClick} data-testid="global-environmentselector-button">
        {/* {infoIcon && <Tooltip title={infoIcon.tooltip}>{infoIcon.icon}</Tooltip>} */}
        <ListItemText>
          <Typography variant="body2" noWrap>
            {reportName}
          </Typography>
          <Typography variant="body2" color="GrayText">
            {organization}
          </Typography>
        </ListItemText>
        <ArrowDropDown />
      </Styled.ListItemButton>
      <Menu
        anchorEl={anchorEl}
        onClose={onClose}
        open={open}
        MenuListProps={{
          //@ts-expect-error data- attrib
          //eslint-disable-next-line
          'data-testid': 'global-environmentselector-list',
        }}
      >
        <TextField
          autoFocus
          value={filter.current}
          onChange={(e) => filter.onChange(e.target.value)}
          placeholder="Search environments"
          variant="standard"
          onKeyDown={(e) => {
            e.stopPropagation();

            if (e.key === 'Enter' && selectFirstItem && filter.current.length) {
              selectFirstItem();
            }
          }}
          sx={{
            mx: 2,
            my: 1,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.MuiInputBase-root:before, .MuiInputBase-root:after': {
              border: 'none !important',
            },
          }}
        />
        <Divider />
        {/*{reports.map((report) => [
          <ListSubheader sx={{ lineHeight: 1.5 }} key={report.name + '1'}>
            {report.name}
          </ListSubheader>,
          <List disablePadding key={report.name + '2'}>
            {report.variants.map((env) => (
              <MenuItem key={env.id} value={env.id} onClick={() => onReportClick(env)} sx={{ pl: 4 }}>
                {env.name}
              </MenuItem>
            ))}
          </List>,
        ])}*/}
        {reportLists.length > 0 ? (
          reportLists
        ) : (
          <ListItem>
            <ListItemText secondary="No environments found" />
          </ListItem>
        )}
      </Menu>
    </>
  );
}
