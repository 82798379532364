import { PostfixOptions } from 'components/PostfixNew/types';
import { useMemo, useState } from 'react';

import { OperandRegexProps } from './index.view';
import { RegexModifiers } from './regexModifiers';

export function useController<T extends PostfixOptions>(props: OperandRegexProps<T>) {
  const { property, currentValue, options } = props;

  if (property.inputType !== 'regex') {
    return null;
  }

  const modifiers = useRegexModifiers(props)!;

  return {
    currentValue,
    modifiers,
    options,
  };
}

function useRegexModifiers<T extends PostfixOptions>(props: OperandRegexProps<T>) {
  const { property, modifierValue, options, onChange } = props;
  const { modifierProperty } = options;

  if (property.inputType !== 'regex') {
    return null;
  }

  const modifiers = useMemo(() => {
    const rawValue = modifierValue;

    if (typeof rawValue !== 'string') {
      return new Set<string>();
    } else {
      return new Set(rawValue.split(''));
    }
  }, [modifierValue]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const addModifier = (modifier: string) => {
    const next = new Set(modifiers);
    next.add(modifier);
    const nextString = Array.from(next).join('');
    onChange((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        [modifierProperty]: nextString,
      },
    }));
  };

  const removeModifier = (modifier: string) => {
    const next = new Set(modifiers);
    next.delete(modifier);
    const nextString = Array.from(next).join('');
    onChange((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        [modifierProperty]: nextString,
      },
    }));
  };

  const finalFlagsString = useMemo(
    () =>
      RegexModifiers.filter((modifier) => modifiers.has(modifier.value))
        .map((modifier) => modifier.value)
        .join(''),
    [modifiers]
  );

  return {
    list: RegexModifiers,
    anchorEl: {
      current: anchorEl,
      onChange: setAnchorEl,
    },
    current: modifiers,
    add: addModifier,
    remove: removeModifier,
    finalFlagsString,
  };
}
