import { PostfixOptions } from 'components/PostfixNew/types';
import { useEffect, useMemo, useState } from 'react';

import { SelectOption } from '../Select/types';

import { OperandAutocompleteOptions, OperandAutocompleteProps } from './index.view';
import { PostfixAutocompleteProperty } from './types';

export function useController<T extends PostfixOptions>(props: OperandAutocompleteProps<T>) {
  const { property, currentValue, onChange, compact, options } = props;

  const { selectOptions, required, allowMultiple: multiple, hidden } = options;

  if (property.inputType !== 'autocomplete') {
    return null;
  }

  const optionsMap = useMemo(() => new Map(selectOptions.map((x) => [x.value, x])), [selectOptions]);

  const selectedOptions = useMemo<SelectOption[]>(() => {
    const rawValue = currentValue;

    if (Array.isArray(rawValue)) {
      return rawValue.map<SelectOption>((x) => {
        const option = optionsMap.get(x);
        if (!option) {
          return {
            label: x ?? '',
            value: x ?? '',
          };
        }

        return option;
      });
    } else {
      const option = optionsMap.get(`${rawValue ?? ''}`);
      if (!option) {
        const newItems: SelectOption[] = [
          {
            label: `${rawValue ?? ''}`,
            value: `${rawValue ?? ''}`,
          },
        ];

        return newItems;
      }

      return [option];
    }
  }, [currentValue, optionsMap]);

  const customInput = useCustomInput(property, selectedOptions, options);

  const errored = useMemo(() => {
    if (customInput?.error) {
      return true;
    }

    if (required) {
      return selectedOptions.filter((x) => x.value.length).length === 0;
    }

    return false;
  }, [customInput?.error, required, selectedOptions]);

  useEffect(() => {
    const rawValue = currentValue;
    const isValueArray = Array.isArray(rawValue);

    if (multiple && !isValueArray) {
      onChange((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          [property.name]: [rawValue],
        },
      }));
    } else if (!multiple && isValueArray) {
      onChange((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          [property.name]: rawValue[0] ?? null,
        },
      }));
    }
  }, [currentValue, multiple, onChange, property.name]);

  useEffect(() => {
    if (
      !hidden &&
      props.property.required &&
      props.property.defaultValue !== undefined &&
      selectedOptions.filter((x) => x.value.length).length === 0
    ) {
      onChange((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          [props.property.name]: Array.isArray(props.property.defaultValue)
            ? props.property.defaultValue
            : [props.property.defaultValue],
        },
      }));
    }
  }, [
    hidden,
    onChange,
    props,
    props.property.defaultValue,
    props.property.name,
    props.property.required,
    selectedOptions,
  ]);

  // validation
  useEffect(() => {
    const currentValues = Array.isArray(currentValue) ? currentValue : [currentValue];

    if (currentValues.length) {
      const newArray: (string | number | boolean)[] = [];

      for (const currentValueItem of currentValues) {
        // validation logic
        // first thing: if the value given is the value provided by the selectOptions, then it's valid
        // second thing: if the value is the defaultValue, then its valid
        // if not, if the value is user entered, then it needs to be validated
        // types to validate
        // - string
        // - number
        // - boolean

        const isFromGivenOptions = optionsMap.has(`${currentValueItem ?? ''}`);

        if (!isFromGivenOptions && (property.defaultValue ? currentValueItem !== property.defaultValue : true)) {
          const defaultValue = property.defaultValue ?? options.selectOptions[0]?.value ?? '';
          if (options.allowMultiple && typeof currentValueItem === 'string' && !currentValueItem.length) {
            // empty string, but multiple is allowed, remove from array
            continue;
          }
          if (options.allowCustomValues) {
            // some value is already set, but its a custom value
            // given the custom input type, try to interpret it

            switch (options.customInputType) {
              case 'number':
                if (typeof currentValueItem === 'number' || typeof currentValueItem === 'string') {
                  // possibly a number
                  const parsed = parseFloat(`${currentValueItem}`);
                  if (!isNaN(parsed)) {
                    newArray.push(parsed);
                    continue;
                  }
                }

                if (!options.allowMultiple) {
                  // not a number, but single value, so set to default
                  newArray.push(0);
                }
                continue;
              default:
              case 'string':
                newArray.push(`${currentValueItem ?? ''}`);
                continue;
            }
          } else {
            if (defaultValue !== undefined && defaultValue !== null) {
              newArray.push(defaultValue as any);
              continue;
            }
          }
        } else {
          newArray.push(currentValueItem as any);
          continue;
        }
      }

      onChange((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          [property.name]: options.allowMultiple ? newArray : newArray[0] ?? '',
        },
      }));
    } else {
      if (options.required && !options.allowCustomValues && !options.allowMultiple) {
        // eslint-disable-next-line no-console
        console.log(property.defaultValue, options.selectOptions[0]?.value);
        onChange((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            [property.name]: property.defaultValue ?? options.selectOptions[0]?.value ?? '',
          },
        }));
      }
    }
  }, [
    currentValue,
    onChange,
    options.allowCustomValues,
    options.allowMultiple,
    options.customInputType,
    options.required,
    options.selectOptions,
    optionsMap,
    property.defaultValue,
    property.name,
  ]);

  return {
    hidden,
    compact,
    customInput,
    multiple,
    error: errored,
    value: {
      onChange,
      list: selectOptions,
      current: selectedOptions,
    },
  };
}

function useCustomInput(
  property: PostfixAutocompleteProperty,
  selectedOption: SelectOption[],
  options: OperandAutocompleteOptions
) {
  const { customInputType: inputType, allowCustomValues: allowNew } = options;
  if (property.inputType !== 'autocomplete') {
    return null;
  }

  const [inputValue, setInputValue] = useState('');

  const inputIsFromSelection = useMemo(
    () => selectedOption.some((x) => x.label === inputValue),
    [inputValue, selectedOption]
  );

  const inputErrored = useMemo(() => {
    if (inputIsFromSelection) {
      // the selection is always valid
      return false;
    }

    if (!inputValue.length) {
      return false;
    }

    if (inputType === 'number') {
      return !inputValue.match(/^\d+\.?\d*$/g);
    }

    return false;
  }, [inputIsFromSelection, inputType, inputValue]);
  if (options.hidden) {
    return null;
  }

  return {
    type: inputType,
    error: inputErrored,
    enabled: allowNew,
    current: inputValue,
    onChange: setInputValue,
    fromSelection: inputIsFromSelection,
  };
}
