import { CheckCircle, Close, Info, WarningAmber } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Modal,
  Slide,
  Snackbar,
  Stack,
} from '@mui/material';
import { Notify } from 'components';
import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

const severityIcons = {
  success: <CheckCircle color="success" />,
  error: <Close color="error" />,
  warning: <WarningAmber color="warning" />,
  info: <Info color="info" />,
};

type CustomSnackbarProps = {
  disableClickAway?: boolean;
  showCloseButton: boolean;
} & Omit<React.ComponentProps<typeof Snackbar>, 'open' | 'onClose'>;

type NotifyProps =
  | (Partial<CustomSnackbarProps> & {
      loader?: boolean;
      severity?: 'success' | 'info' | 'warning' | 'error';
    })
  | null;

type ConfirmOptions = {
  title: string;
  delete?: boolean;
  yesNo?: boolean;
};

interface NotifyContextType {
  /**
   * @deprecated Alert is a limited functionaltiy drop-in for snackbar, use the notify function instead.
   */
  //eslint-disable-next-line
  Alert: (title: string) => void;
  notify: (props: NotifyProps) => void;
  confirm: (options: ConfirmOptions) => Promise<boolean>;
}
const NotifyContext = createContext<NotifyContextType>({} as NotifyContextType);

const disabledClickAwayProps = { onClickAway: () => null };

const SlideUp = (p: any) => <Slide {...p} direction="up" />;

/**
 * Represents custom webhook for notification
 * @function
 * @name NotificationProvider
 * @param {object} children - child components
 * @return {ReactJSXElement} jsx component
 */
export function NotificationProvider({ children }: { children: ReactNode }): JSX.Element {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');

  const [customProps, setCustomProps] = useState<NotifyProps>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [confirmModals, setConfirmModals] = useState<
    {
      options: ConfirmOptions;
      id: string;
      callback: (accept: boolean) => void;
    }[]
  >([]);

  /**
   * Show alert message
   * @function
   * @name Alert
   * @param {string} message - alert string
   * @return {void}
   */
  const Alert = (message: string) => {
    setTitle(message);
    setOpen(true);
  };

  const notify: NotifyContextType['notify'] = useCallback((props) => {
    if (props === null) {
      setSnackbarOpen(false);
      return;
    }
    const { disableClickAway = true, showCloseButton = true, loader = false, severity, ...rest } = props;
    if (disableClickAway) {
      rest.ClickAwayListenerProps = disabledClickAwayProps;
    }
    if (showCloseButton) {
      rest.action = (
        <IconButton onClick={() => setSnackbarOpen(false)} color="inherit">
          <Close />
        </IconButton>
      );
    }
    if (loader) {
      rest.message = (
        <Stack gap={2} direction="row" alignItems="center">
          <CircularProgress size={22} color="inherit" />
          {rest.message}
        </Stack>
      );
      props.message = undefined;
    } else if (severity) {
      rest.message = (
        <Stack gap={2} direction="row" alignItems="center">
          {severityIcons[severity]}
          {rest.message}
        </Stack>
      );
    }
    setCustomProps(rest);
    setSnackbarOpen(true);
  }, []);

  const confirm = async (options: ConfirmOptions) => {
    return new Promise<boolean>((resolve) => {
      const id = window.crypto.randomUUID();
      async function callback(accept: boolean) {
        setConfirmModals((prev) => prev.filter((m) => m.id !== id));
        resolve(accept);
      }
      setConfirmModals((prev) => [...prev, { options, id, callback }]);
    });
  };

  return (
    <NotifyContext.Provider
      value={{
        Alert,
        notify,
        confirm,
      }}
    >
      {
        <>
          {children}
          {confirmModals.map((m) => (
            <Modal
              key={m.id}
              open
              onClose={() => m.callback(false)}
              sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Card>
                <CardContent>{m.options.title}</CardContent>
                <CardActions
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button onClick={() => m.callback(false)}>{m.options.yesNo ? 'No' : 'Cancel'}</Button>
                  <Button color={m.options.delete ? 'error' : undefined} onClick={() => m.callback(true)}>
                    {m.options.delete ? 'Yes, Delete' : m.options.yesNo ? 'Yes' : 'Accept'}
                  </Button>
                </CardActions>
              </Card>
            </Modal>
          ))}
          <Notify openErrorAlert={open} title={title} onClose={() => setOpen(false)} />
          <Snackbar
            autoHideDuration={6000}
            TransitionComponent={SlideUp}
            {...customProps}
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          />
        </>
      }
    </NotifyContext.Provider>
  );
}

export default function useNotify() {
  return useContext(NotifyContext);
}
