import styled from '@emotion/styled';
import { Box, TextField as MuiTextField, Theme } from '@mui/material';

export const FilledTextField = styled(MuiTextField)(
  ({ theme }: { theme?: Theme }) => `
  .MuiInputBase-root {
    background-color: ${theme?.palette.background.paper};
  }
  `
);

export const Backdrop = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px) saturate(1);
`;
