import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useDataExportDimensionsAndMetrics } from 'api';
import { ObjectWithMessage } from 'api/events/viewer';
import { useReportGuids } from 'api/hooks/useEnvGuid';
import PostfixNew from 'components/PostfixNew';
import { SelectOption } from 'components/PostfixNew/components/Operand/components/Select/types';
import { PostfixGroupSchema, PostfixOptions } from 'components/PostfixNew/types';
import React, { useMemo, useState } from 'react';

import { comparators } from 'pages/data-export/components/Segmentation/comparators';
import { getFaviconForTechnology } from 'pages/explore/explore.controller';

export default function ComponentDemosPage() {
  const { envGuid } = useReportGuids();
  const { data: dimensions } = useDataExportDimensionsAndMetrics(envGuid);
  const [compact, setCompact] = useState(false);

  const dimensionsMapped = useMemo<SelectOption[]>(
    () =>
      dimensions?.segmentationDimensions
        .filter((x, _, a) => {
          if (x.source === 'tags.variableConfigs' && !x.eventDimension) {
            const firstTagVariable = a.find((y) => y.source === 'tags.variableConfigs' && y.tagId === x.tagId);

            return firstTagVariable?.id === x.id;
          }

          return x.source == 'tags.variableConfigs';
        })
        .map((dimension) => ({
          value: dimension.id,
          label:
            dimension.source === 'dimensions' || dimension.eventDimension
              ? dimension.friendlyName
              : dimension.tagFriendlyName,
          group: dimension.source === 'dimensions' || dimension.eventDimension ? dimension.group : 'Tag Group Variable',
          icon:
            dimension.source === 'tags.variableConfigs' && !dimension.eventDimension ? (
              <img width={20} src={getFaviconForTechnology(dimension.tagFriendlyName, true)} />
            ) : undefined,
        }))
        .sort((a, b) => {
          if (a.group === b.group) {
            return a.label.localeCompare(b.label);
          } else {
            return (a.group ?? '').localeCompare(b.group ?? '');
          }
        }) ?? [],
    [dimensions?.segmentationDimensions]
  );

  const postfixOptions = useMemo<PostfixOptions>(
    () => ({
      properties: [
        {
          inputType: 'autocomplete',
          name: 'propertyPath',
          label: 'Event Source',
          defaultValue: dimensionsMapped[0]?.value,
          options: {
            selectOptions: () => dimensionsMapped,
          },
        },
        {
          inputType: 'autocomplete',
          defaultValue: null,
          name: 'propertyPathSecondDropdown',
          label: 'Event Source (Second)',
          options: {
            selectOptions: () => [
              { value: 'test', label: 'Test' },
              { value: 'test2', label: 'Test 2' },
            ],
            hidden: (row) => {
              const propertyPathId = row.properties['propertyPath'];

              if (typeof propertyPathId === 'string') {
                const propertyPath = dimensions?.segmentationDimensions.find((x) => x.id === propertyPathId);

                if (propertyPath) {
                  if (propertyPath.source === 'tags.variableConfigs' && !propertyPath.eventDimension) {
                    return false;
                  }
                }
              }

              return true;
            },
          },
        },
        {
          inputType: 'select',
          name: 'comparator',
          default: 'equals',
          label: 'Property 2',
          options: {
            selectOptions: (row) => {
              const comparisonAttributeRaw = row.properties['comparisonAttribute'];
              let comparatorLimitingType = '';

              if (typeof comparisonAttributeRaw === 'string') {
                switch (comparisonAttributeRaw) {
                  case 'type':
                    comparatorLimitingType = 'attribute_type';
                    break;
                  case 'length':
                    comparatorLimitingType = 'attribute_length';
                    break;
                }
              }

              return Object.entries(comparators)
                .filter(([_, value]) =>
                  comparatorLimitingType ? value.supportedTypes.includes(comparatorLimitingType) : true
                )
                .map(([name, value]) => ({
                  value: name,
                  label: value.friendlyName,
                }));
            },
            tabs: () => ({
              options: [
                { value: 'value', label: 'Value' },
                { value: 'type', label: 'Value Type' },
                { value: 'length', label: 'Value Length' },
              ],
              property: 'comparisonAttribute',
            }),
            modifierButtons: (row) => {
              const comparisonAttributeRaw = row.properties['comparisonAttribute'];

              if (!comparisonAttributeRaw || comparisonAttributeRaw === 'value') {
                return [
                  {
                    property: 'ignoreCase',
                    label: 'Ignore Case',
                  },
                ];
              }

              return [];
            },
          },
        },
        {
          inputType: 'autocomplete',
          name: 'comparisonValue',
          defaultValue: null,
          label: 'Value',
          options: {
            customInputType: (row) => {
              const comparisonAttributeRaw = row.properties['comparisonAttribute'];
              const comparator = row.properties['comparator'];

              if (comparisonAttributeRaw === 'length') {
                return 'number';
              }

              if (
                comparator === 'lessThan' ||
                comparator === 'lessThanOrEquals' ||
                comparator === 'greaterThan' ||
                comparator === 'greaterThanOrEquals'
              ) {
                return 'number';
              }

              return 'string';
            },
            allowMultiple: (row) => {
              const comparator = row.properties['comparator'];

              if (comparator === 'isOneOf' || comparator === 'isNotOneOf') {
                return true;
              }

              return false;
            },
            selectOptions: (row) => {
              const comparisonAttributeRaw = row.properties['comparisonAttribute'];
              const comparator = row.properties['comparator'];

              if (comparisonAttributeRaw === 'type') {
                return [
                  { value: 'string', label: 'String' },
                  { value: 'number', label: 'Number' },
                  { value: 'object', label: 'Object' },
                  { value: 'boolean', label: 'Boolean' },
                  { value: 'undefined', label: 'Undefined' },
                  { value: 'function', label: 'Function' },
                ];
              }

              if (comparisonAttributeRaw === 'length') {
                return [];
              }

              if (
                comparator === 'lessThan' ||
                comparator === 'lessThanOrEquals' ||
                comparator === 'greaterThan' ||
                comparator === 'greaterThanOrEquals'
              ) {
                return [];
              }

              return [{ value: 'test', label: 'Test' }];
            },
            allowCustomValues: (row) => {
              const comparisonAttributeRaw = row.properties['comparisonAttribute'];

              if (comparisonAttributeRaw === 'type') {
                return false;
              }

              return true;
            },
            required: () => true,
            hidden: (row) => {
              const comparator = row.properties['comparator'];

              if (comparator === 'matchesRegex' || comparator === 'doesNotMatchRegex') {
                return true;
              }

              if (
                comparator === 'isTruthy' ||
                comparator === 'isFalsy' ||
                comparator === 'isNull' ||
                comparator === 'isNotNull' ||
                comparator === 'exists' ||
                comparator === 'doesNotExist' ||
                comparator === 'isTrue' ||
                comparator === 'isFalse'
              ) {
                return true;
              }

              return false;
            },
          },
        },
        {
          inputType: 'regex',
          name: 'comparisonValue',
          defaultValue: '',
          label: 'Value',
          options: {
            modifierProperty: () => 'comparisonValueFlags',
            hidden: (row) => {
              const comparator = row.properties['comparator'];

              if (typeof comparator === 'string') {
                if (comparator === 'matchesRegex' || comparator === 'doesNotMatchRegex') {
                  return false;
                }
              }

              return true;
            },
          },
        },
      ],
    }),
    [dimensions?.segmentationDimensions, dimensionsMapped]
  );

  const [value, setValue] = useState<PostfixGroupSchema<typeof postfixOptions>>({
    comparator: 'and',
    operands: [
      {
        type: 'operand',
        properties: {
          propertyPath: dimensionsMapped[0]?.value,
          comparator: 'equals',
          comparisonValue: 'test',
        },
      },
      {
        type: 'operand',
        properties: {
          propertyPath: dimensionsMapped[0]?.value,
          comparator: 'matchesRegex',
          comparisonValue: 'test',
          comparisonValueFlags: 'g',
        },
      },
    ],
    type: 'group',
  });

  return (
    <Stack gap={1} px={4} pb={6} overflow="auto">
      <Typography variant="h4">Component Demos</Typography>
      <Stack direction="row">
        <Button
          startIcon={compact ? <CheckBox /> : <CheckBoxOutlineBlank />}
          onClick={() => setCompact((prev) => !prev)}
        >
          Compact
        </Button>
      </Stack>
      <PostfixNew compact={compact} value={value} onChange={setValue} options={postfixOptions} />
      <ObjectWithMessage object={value} />
    </Stack>
  );
}
