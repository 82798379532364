import { SelectOption } from '../Select/types';

export const RegexModifiers: SelectOption[] = [
  { value: 'g', label: 'global', description: "Don't return after first match" },
  { value: 'm', label: 'multi line', description: 'A and $ match start/end of line' },
  { value: 'i', label: 'insensitive', description: 'Case insensitive match' },
  { value: 'x', label: 'extended', description: 'Ignore whitespace' },
  { value: 's', label: 'single line', description: 'Dot matches newline' },
  { value: 'u', label: 'unicode', description: 'Match with full unicode' },
  { value: 'U', label: 'Ungreedy', description: 'Make quantifiers lazy' },
  {
    value: 'A',
    label: 'Anchored',
    description: 'Anchor to start of pattern, or at the end of the most recent match',
  },
  { value: 'J', label: 'Jchanged', description: 'Allow duplicate subpattern names' },
  { value: 'D', label: 'Dollar end only', description: '$ matches only end of pattern' },
];
