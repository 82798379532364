import { PostfixOperandSchema, PostfixOptions, PreviousStatePredicate } from 'components/PostfixNew/types';
import React, { createContext, useContext, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';

import { PostfixAutocompleteProperty } from '../Autocomplete/types';

import { OperandSelectOptions, View } from './index.view';
import { PostfixSelectProperty } from './types';

function BlankComponent(_props: any) {
  return null;
}

export const SelectContext = createContext<{
  value: PostfixOperandSchema<{
    properties: PostfixAutocompleteProperty[];
  }>['properties'];
  onChange: PreviousStatePredicate<PostfixOperandSchema<PostfixOptions>>;
} | null>(null);

export function OperandSelect<T extends PostfixOptions>(props: {
  value: PostfixOperandSchema<{
    properties: PostfixAutocompleteProperty[];
  }>;
  property: PostfixSelectProperty;
  onChange: PreviousStatePredicate<PostfixOperandSchema<T>>;
  compact?: boolean;
}) {
  const { value, property } = props;

  const previousOptions = useRef<OperandSelectOptions | null>(null);
  const previousValue = useRef<unknown>();

  const options = useMemo(() => {
    const newOptions: OperandSelectOptions = {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      topComponent: property.options.topComponent?.(value) ?? BlankComponent,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      bottomComponent: property.options.bottomComponent?.(value) ?? BlankComponent,
      tabs: property.options.tabs?.(value),
      modifierButtons: property.options.modifierButtons?.(value) ?? [],
      selectOptions: property.options.selectOptions(value),
      hidden: property.options.hidden?.(value) ?? false,
    };

    if (!isEqual(previousOptions.current, newOptions)) {
      previousOptions.current = newOptions;
    }

    return previousOptions.current ?? newOptions;
  }, [property.options, value]);

  const currentValue = useMemo(() => {
    const newValue = value.properties[property.name];

    if (!isEqual(previousValue.current, newValue)) {
      previousValue.current = newValue;
    }

    return previousValue.current;
  }, [property.name, value.properties]);

  const view = useMemo(
    () => (
      <View
        onChange={props.onChange}
        options={options}
        property={property}
        currentValue={currentValue}
        compact={props.compact}
      />
    ),
    [currentValue, options, property, props.compact, props.onChange]
  );

  if (options.hidden) return null;

  return (
    <SelectContext.Provider value={{ value: value.properties, onChange: props.onChange }}>
      {view}
    </SelectContext.Provider>
  );
}

export function useSelectContext() {
  const selectContext = useContext(SelectContext);

  return selectContext;
}
