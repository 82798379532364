import { PostfixOperandSchema, PostfixOptions, PreviousStatePredicate } from 'components/PostfixNew/types';
import React, { useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';

import { OperandAutocompleteOptions, View } from './index.view';
import { PostfixAutocompleteProperty } from './types';

export function OperandAutocomplete<T extends PostfixOptions>(props: {
  property: PostfixAutocompleteProperty;
  value: PostfixOperandSchema<{
    properties: PostfixAutocompleteProperty[];
  }>;
  onChange: PreviousStatePredicate<PostfixOperandSchema<T>>;
  compact?: boolean;
}) {
  const { property, value, onChange, compact } = props;

  const previousValue = useRef<unknown>();
  const previousOptions = useRef<OperandAutocompleteOptions | null>(null);

  const options = useMemo<OperandAutocompleteOptions>(() => {
    const newOptions: OperandAutocompleteOptions = {
      selectOptions: property.options.selectOptions(value),
      hidden: property.options.hidden?.(value) ?? false,
      allowMultiple: property.options.allowMultiple?.(value) ?? false,
      required: property.options.required?.(value) ?? false,
      allowCustomValues: property.options.allowCustomValues?.(value),
      customInputType: property.options.customInputType?.(value) ?? 'string',
    };

    if (!isEqual(previousOptions.current, newOptions)) {
      previousOptions.current = newOptions;
    }

    return previousOptions.current ?? newOptions;
  }, [property.options, value]);

  const currentValue = useMemo(() => {
    const newValue = value.properties[property.name];

    if (!isEqual(previousValue.current, newValue)) {
      previousValue.current = newValue;
    }

    return previousValue.current;
  }, [property.name, value.properties]);

  const view = useMemo(() => {
    return (
      <View property={property} onChange={onChange} compact={compact} currentValue={currentValue} options={options} />
    );
  }, [compact, currentValue, onChange, options, property]);

  if (options.hidden) {
    return null;
  }

  return view;
}
