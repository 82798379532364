import { MiddlewareError, MiddlewareEvent } from 'api/events';
import { useMiddlewareHookInit, useMiddlewareHookUpdate } from 'api/events/hooks';
import { ClickHouseApiQueryVariables, ClickHouseApiQueryVariablesSchema } from 'api/pages/schemas/clickhouse';
import routes from 'api/routes';
import moment, { Moment } from 'moment';
import useSWR, { useSWRConfig } from 'swr';

import { useAxios } from 'utils/transport/useAxios';

import { dashboardDetailsModelToViewModel } from '../../schemas/insights/details/details.viewmodel';

import { DashboardEventOptions } from './eventOptions';

export function useDashboardDetails(
  id: string | null,
  queryVariables: ClickHouseApiQueryVariables | null,
  parentEvent?: MiddlewareEvent
) {
  const initEvent = useMiddlewareHookInit(DashboardEventOptions.namespace, `useDashboardDetails()`, parentEvent?.id, {
    id,
    queryVariables,
  });
  const updateEvent = useMiddlewareHookUpdate(DashboardEventOptions.namespace, `useDashboardDetails()`, initEvent?.id, {
    id,
    queryVariables,
  });

  const { post } = useAxios();
  const { cache } = useSWRConfig();

  const payloadValid = id && queryVariables && ClickHouseApiQueryVariablesSchema.safeParse(queryVariables).success;

  const finalUrl = id ? routes.dashboard.details(id) : undefined;

  const result = useSWR(
    payloadValid ? JSON.stringify({ url: finalUrl, payload: queryVariables }) : null,
    async (json) => {
      const fetchEvent = new MiddlewareEvent({
        ...DashboardEventOptions,
        source: 'network',
        method: 'POST',
        name: 'SWR Axios Request',
        path: `useSWR()`,
        data: json,
        parent: updateEvent.id, // to record the init AND the update event
      });

      let data: unknown = null;

      try {
        const { url } = JSON.parse(json);
        cache.set(finalUrl + ':lastUpdate', {
          data: moment(),
        });
        const response = await post(url, queryVariables, {
          withCredentials: true,
        });

        data = response.data;

        if (!data) throw new Error('Data is empty');
        fetchEvent.status = 'completed';
      } catch (error) {
        fetchEvent.status = 'failed';
        fetchEvent.resultText = `${error}`;

        const middlewareError = new MiddlewareError({
          cause: error instanceof Error ? error : new Error(`${error}`),
          event: fetchEvent,
          viewType: 'data',
        });

        throw middlewareError;
      }

      return dashboardDetailsModelToViewModel(data, fetchEvent);
    },
    {
      errorRetryCount: 2,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      // revalidateOnMount: true,
      // refreshInterval: 1000 * 60, // 1 minute
    }
  );

  updateEvent.status = 'completed';

  const lastUpdate: Moment | undefined = cache.get(finalUrl + ':lastUpdate')?.data ?? undefined;

  return {
    ...result,
    lastUpdate,
  };
}
