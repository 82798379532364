import { MiddlewareError, MiddlewareEvent } from 'api/events';
import { useMiddlewareHookInit, useMiddlewareHookUpdate } from 'api/events/hooks';
import { ClickHouseApiQueryVariables, ClickHouseApiQueryVariablesSchema } from 'api/pages/schemas/clickhouse';
import routes from 'api/routes';
import useSWR from 'swr';

import { useAxios } from 'utils/transport/useAxios';

import { dashboardInsightsModelToViewModel } from '../../schemas/insights/leftnav/leftnav.viewmodel';

import { DashboardEventOptions } from './eventOptions';

// TODO: replace ALL swr calls with arrays for keys
export function useDashboardInsights(
  queryVariables: ClickHouseApiQueryVariables | null,
  parentEvent?: MiddlewareEvent
) {
  const initEvent = useMiddlewareHookInit(
    DashboardEventOptions.namespace,
    `useDashboardInsights()`,
    parentEvent?.id,
    queryVariables
  );
  const updateEvent = useMiddlewareHookUpdate(
    DashboardEventOptions.namespace,
    `useDashboardInsights()`,
    initEvent?.id,
    queryVariables
  );

  const { post } = useAxios();

  const payloadValid = queryVariables && ClickHouseApiQueryVariablesSchema.safeParse(queryVariables).success;

  const result = useSWR(
    payloadValid ? JSON.stringify({ url: routes.dashboard.insights(), payload: queryVariables }) : null,
    async (json) => {
      const fetchEvent = new MiddlewareEvent({
        ...DashboardEventOptions,
        source: 'network',
        method: 'POST',
        name: 'SWR Axios Request',
        path: `useSWR()`,
        data: json,
        parent: updateEvent.id, // to record the init AND the update event
      });

      let data: unknown = null;

      try {
        const { url } = JSON.parse(json);
        const response = await post(url, queryVariables, {
          withCredentials: true,
        });

        data = response.data;

        if (!data) throw new Error('Data is empty');
        fetchEvent.status = 'completed';
      } catch (error) {
        fetchEvent.status = 'failed';
        fetchEvent.resultText = `${error}`;

        const middlewareError = new MiddlewareError({
          cause: error instanceof Error ? error : new Error(`${error}`),
          event: fetchEvent,
          viewType: 'data',
        });

        throw middlewareError;
      }

      return dashboardInsightsModelToViewModel(data, fetchEvent);
    },
    {
      errorRetryCount: 2,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      // revalidateOnMount: true,
      // refreshInterval: 1000 * 60, // 1 minute
    }
  );

  updateEvent.status = 'completed';

  return result;
}
