import { useAxios } from 'api';
import { ApiDataExportDimensionsAndMetricsSchema, DataExportDimensionsAndMetrics, EnvironmentId } from 'api/apiTypes';
import routes from 'api/routes';
import useSWR, { SWRResponse } from 'swr';

export function useDataExportDimensionsAndMetrics(
  envGuid: EnvironmentId | null
): SWRResponse<DataExportDimensionsAndMetrics> {
  const { get } = useAxios();

  return useSWR(
    envGuid ? routes.dataExport.getDimensionsAndMetrics(envGuid ?? ('' as EnvironmentId)) : null,
    async (url) => {
      const response = await get(url, { withCredentials: true });
      return ApiDataExportDimensionsAndMetricsSchema.parse(response.data);
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 3,
      errorRetryInterval: 5000,
    }
  );
}
