import { Add, RemoveCircleOutline } from '@mui/icons-material';
import { Button, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { PostfixGroupSchema, PostfixOptions, PreviousStatePredicate } from '../../types';
import { PostfixOperand } from '../Operand';

import { PostfixGroup } from '.';

export type PostfixGroupProps<T extends PostfixOptions> = {
  // The original schema passed into the postfix component is also passed into the group
  options: T;
  value: PostfixGroupSchema<T>;
  // internally used to keep track of how deep the group is nested currently
  depth: number;
  onChange: PreviousStatePredicate<PostfixGroupSchema<T>>;
  compact?: boolean;
};

export function View<T extends PostfixOptions>(props: PostfixGroupProps<T>) {
  const { depth, value, onChange, options, compact } = props;

  return (
    <Stack
      gap={compact ? 1 : 2}
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        p: compact ? 1 : 2,
        pb: 1,
        pr: compact ? 0 : 1,
        borderRadius: 2,
        bgcolor: depth % 2 === 0 ? 'background.paper' : 'background.verylight',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& > *': {
          flexGrow: 1,
          flexShrink: 0,
        },
      }}
    >
      {value.operands.map((operand, index) => {
        return [
          ...(index > 0
            ? [
                index === 1 ? (
                  <ToggleButtonGroup
                    key={index + '-button'}
                    value={value.comparator}
                    size={compact ? 'small' : 'medium'}
                  >
                    <ToggleButton
                      value="and"
                      onClick={() =>
                        onChange((prev) => ({
                          ...prev,
                          comparator: 'and',
                        }))
                      }
                      sx={{
                        py: 0,
                      }}
                    >
                      And
                    </ToggleButton>
                    <ToggleButton
                      value="or"
                      onClick={() =>
                        onChange((prev) => ({
                          ...prev,
                          comparator: 'or',
                        }))
                      }
                      sx={{
                        py: 0,
                      }}
                    >
                      Or
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  <Typography textTransform="uppercase">{value.comparator}</Typography>
                ),
              ]
            : []),
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            gap={compact ? (value.operands.length <= 1 ? 1 : 0) : 1}
            sx={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '& > :first-child': {
                flexGrow: 1,
              },
            }}
          >
            {operand.type === 'group' ? (
              <PostfixGroupProxy
                compact={compact}
                depth={depth + 1}
                options={options}
                value={operand}
                index={index}
                onChange={onChange}
              />
            ) : (
              <PostfixOperandProxy
                compact={compact}
                properties={options.properties}
                value={operand}
                index={index}
                onChange={onChange}
              />
            )}
            {value.operands.length > 1 ? (
              <IconButton
                color="error"
                onClick={() =>
                  onChange((prev) => {
                    const newArr = Array.from(prev.operands);
                    // eslint-disable-next-line sentinelinsights/no-mutations
                    newArr.splice(index, 1);
                    return { ...prev, operands: newArr };
                  })
                }
              >
                <RemoveCircleOutline fontSize={compact ? 'small' : 'medium'} />
              </IconButton>
            ) : (
              <div />
            )}
          </Stack>,
        ];
      })}
      <Stack direction="row">
        <Button
          size="small"
          startIcon={<Add />}
          onClick={() => {
            onChange((prev) => ({
              ...prev,
              operands: [
                ...prev.operands,
                {
                  type: 'operand',
                  properties: options.properties.reduce(
                    (acc, property) => ((acc[property.name] = property.defaultValue ?? undefined), acc),
                    {} as Record<string, unknown>
                  ),
                },
              ],
            }));
          }}
        >
          New Operand
        </Button>
        <Button
          size="small"
          startIcon={<Add />}
          onClick={() => {
            onChange((prev) => ({
              ...prev,
              operands: [
                ...prev.operands,
                {
                  type: 'group',
                  comparator: 'and',
                  operands: [
                    {
                      type: 'operand',
                      properties: options.properties.reduce(
                        (acc, property) => ((acc[property.name] = property.defaultValue ?? undefined), acc),
                        {} as Record<string, unknown>
                      ),
                    },
                  ],
                },
              ],
            }));
          }}
        >
          New Group
        </Button>
      </Stack>
    </Stack>
  );
}

function PostfixGroupProxy(
  props: React.ComponentProps<typeof PostfixGroup> & {
    index: number;
  }
) {
  const { index, compact, depth, options, value: operand, onChange } = props;

  const onChangeFunction = useCallback<PreviousStatePredicate<any>>(
    (predicate) =>
      onChange((prev) => {
        const newArr = Array.from(prev.operands);
        newArr[index] = predicate(prev.operands[index]);
        return { ...prev, operands: newArr };
      }),
    [index, onChange]
  );

  return <PostfixGroup compact={compact} depth={depth} options={options} value={operand} onChange={onChangeFunction} />;
}

function PostfixOperandProxy(
  props: Omit<React.ComponentProps<typeof PostfixOperand>, 'onChange'> &
    Pick<React.ComponentProps<typeof PostfixGroup>, 'onChange'> & {
      index: number;
    }
) {
  const { index, compact, properties, value: operand, onChange } = props;

  const onChangeFunction = useCallback<PreviousStatePredicate<any>>(
    (predicate) =>
      onChange((prev) => {
        const newArr = Array.from(prev.operands);
        newArr[index] = predicate(prev.operands[index]);
        return { ...prev, operands: newArr };
      }),
    [index, onChange]
  );

  return <PostfixOperand compact={compact} properties={properties} value={operand} onChange={onChangeFunction} />;
}
