import { FeatureFlagDefinition } from '../types/feature';

// ALL ID's ARE AUTOMATICALLY PREFIXED WITH "feature." DURING POST PROCESSING
const defaultFeatureFlags: FeatureFlagDefinition[] = [
  {
    id: 'sotPublish',
    name: 'SOT Publish',
    enabled: false,
    description: "Allows user's to publish SOT configurations to the CDN",
    parent: {
      id: 'role.internalUser',
    },
  },
  {
    id: 'configureConsent',
    name: 'Configure Consent',
    enabled: false,
    description: "Allows user's to configure consent settings for an environment",
    parent: {
      id: 'role.internalUser',
    },
  },
  {
    id: 'dashboard.successAndDisabledBubbles',
    name: 'Api Dashboard - Success & Disabled Bubbles',
    description: 'Shows the success and disabled bubbles on the API dashboard',
    enabled: true,
  },
  {
    id: 'insights.settings',
    name: 'Insights Page - Settings',
    description: 'Enables the settings option on the insights page',
    enabled: true,
  },
  {
    id: 'insights.settings.bpState.custom',
    name: 'Insights Page - Settings - BP State - Custom Selection',
    description: 'Allows selecting a custom BP state for any number of reports',
    enabled: false,
    parent: {
      id: 'role.internalUser',
    },
  },
  {
    id: 'insights.settings.bpCustomization',
    name: 'Insights Page - Settings - BP Customization',
    description: 'Allows customizing a best practice with custom rules',
    enabled: false,
    parent: {
      id: 'role.internalUser',
    },
  },
];

export default defaultFeatureFlags;
