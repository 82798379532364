import { Check, KeyboardArrowDown } from '@mui/icons-material';
import { Button, Divider, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { PostfixOperandSchema, PostfixOptions, PreviousStatePredicate } from 'components/PostfixNew/types';
import React from 'react';

import { FilledTextField } from 'pages/data-export/dataExport.styles';

import { PostfixOperandProperty, UnwrapPostfixOptionFunctionTypes } from '../../types';

import { useController } from './index.controller';
import { PostfixRegexProperty } from './types';

export type OperandRegexOptions = UnwrapPostfixOptionFunctionTypes<PostfixRegexProperty['options']>;

export type OperandRegexProps<T extends PostfixOptions> = {
  property: PostfixOperandProperty;
  currentValue: unknown;
  onChange: PreviousStatePredicate<PostfixOperandSchema<T>>;
  compact?: boolean;
  options: OperandRegexOptions;
  modifierValue?: unknown;
};

export function View<T extends PostfixOptions>(props: OperandRegexProps<T>) {
  const state = useController(props);

  if (!state) return null;

  const { modifiers, currentValue } = state;

  return (
    <FilledTextField
      sx={{
        minWidth: 300,
      }}
      value={currentValue}
      onChange={(e) =>
        props.onChange((prev) => ({
          ...prev,
          properties: { ...prev.properties, [props.property.name]: e.target.value },
        }))
      }
      InputProps={{
        startAdornment: <Typography>/</Typography>,
        endAdornment: (
          <>
            <Divider orientation="vertical" flexItem />
            <Button
              onClick={(e) => modifiers.anchorEl.onChange(e.currentTarget)}
              sx={{
                ml: 1,
                textTransform: 'none',
              }}
              endIcon={<KeyboardArrowDown />}
            >
              /{modifiers.finalFlagsString}
            </Button>
            <Menu
              anchorEl={modifiers.anchorEl.current}
              open={Boolean(modifiers.anchorEl.current)}
              onClose={() => modifiers.anchorEl.onChange(null)}
            >
              {modifiers.list.map((modifier) => (
                <MenuItem
                  key={modifier.value}
                  value={modifier.value}
                  onClick={() => {
                    if (modifiers.current.has(modifier.value)) {
                      modifiers.remove(modifier.value);
                    } else {
                      modifiers.add(modifier.value);
                    }
                  }}
                >
                  <ListItemText primary={modifier.label} secondary={modifier.description} />
                  {modifiers.current.has(modifier.value) && (
                    <ListItemSecondaryAction>
                      <Check />
                    </ListItemSecondaryAction>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </>
        ),
      }}
    />
  );
}
