import { CardActionArea, Stack } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { useSwissWidget } from './SwissWidget.hooks';
import { Card } from './SwissWidget.styles';

export type SwissWidgetProps = {
  passed?: boolean;
  title?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  icon?: React.ReactNode;
  variant?: 'normal' | 'small';
  headerFooterColor?: string;
  backgroundColor?: string;
  textColor?: string;
  to?: string;
} & Omit<React.ComponentProps<typeof Stack>, 'title'>;

/**
 * Represents a widget that shows a number (title), icon, and a caption
 * @namespace Widgets
 * @param {string} type - Type of the widget (error, info, success, warning)
 * @param {string} title - Title of the widget
 * @param {string} caption - Caption of the widget
 * @param {ReactNode} icon - Icon of the widget
 * @returns ReactJSXElement
 */
export default function SwissWidget(props: SwissWidgetProps) {
  const { textColor, backgroundColor, title, header, footer, icon, children, to, ...rest } = useSwissWidget(props);

  return (
    <SwissButton to={to}>
      <Card textColor={textColor} backgroundColor={backgroundColor} data-testid="swiss-widget" {...rest}>
        {header}
        <Stack direction="row" alignItems="center" gap={1}>
          {icon}
          <Stack>
            {children}
            {title}
          </Stack>
        </Stack>
        {footer}
      </Card>
    </SwissButton>
  );
}

function SwissButton(props: React.PropsWithChildren<{ to?: string }>) {
  const { to, children } = props;
  if (!to) {
    return <>{children}</>;
  }
  return (
    <CardActionArea
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LinkComponent={(props: { to: string }) => <Link {...props} to={to} />}
      href={to}
      sx={{
        borderRadius: 4,
      }}
    >
      {children}
    </CardActionArea>
  );
}
