import { getEnabledFeatureFlags } from 'api/utils';
import React, { useCallback, useContext, useEffect } from 'react';

// TODO: idk, this was made very quickly, i guess complete redesign is needed

/* eslint-disable @typescript-eslint/naming-convention */
type StoreType = {
  'flag.isOldWebsite': boolean;
  'flag.isDevWebsite': boolean;
  'flag.isProdWebsite': boolean;
  'flag.isLocalWebsite': boolean;
  'pages.admin.enabled': boolean;
  'pages.admin.visible': boolean;
  'pages.internalAdmin.enabled': boolean;
  'pages.internalAdmin.visible': boolean;
  'pages.privacy.enabled': boolean;
  'pages.privacy.visible': boolean;
  'pages.technology.enabled': boolean;
  'pages.technology.visible': boolean;
  'pages.notifications.enabled': boolean;
  'pages.notifications.visible': boolean;
  'pages.explore.enabled': boolean;
  'pages.explore.visible': boolean;
  'pages.dataExport.enabled': boolean;
  'pages.dataExport.visible': boolean;
  'pages.knowledgeBase.enabled': boolean;
  'pages.knowledgeBase.visible': boolean;
  'user.isInternal': boolean;
  'user.isPowerUser': boolean;
  'user.allowPublish': boolean;
  'user.isSotAdmin': boolean;
  'feature.insight.labels': boolean;
  'feature.insight.comments': boolean;
  'feature.environmentselector.favorites': boolean;
  [key: string]: boolean;
};

const isLocalHost = window.location.hostname === 'localhost';

export const globalExperimentsStore: StoreType = {
  // default enabled
  'feature.insight.labels': true,
  'feature.insight.comments': true,
  'pages.notifications.enabled': true,
  'pages.notifications.visible': true,
  'pages.internalAdmin.enabled': true,
  'pages.dataExport.enabled': true,
  'pages.dataExport.visible': true,
  'pages.knowledgeBase.enabled': true,
  'pages.knowledgeBase.visible': true,
  // internal user (no different value, just organizing)

  // default disabled
  'pages.explore.enabled': true || isLocalHost,
  'pages.explore.visible': true || isLocalHost,
  'feature.environmentselector.favorites': false || isLocalHost,
  'pages.privacy.visible': false || isLocalHost,
  'user.isInternal': false || isLocalHost,
  'user.isPowerUser': false || isLocalHost,
  'user.isSotAdmin': false || isLocalHost,
  'user.allowPublish': false || isLocalHost,
  'pages.admin.enabled': false || isLocalHost,
  'pages.admin.visible': false || isLocalHost,
  'pages.internalAdmin.visible': false || isLocalHost,
  'pages.privacy.enabled': false || isLocalHost,
  'pages.technology.enabled': false || isLocalHost,
  'pages.technology.visible': false || isLocalHost,
  // conditionals
  'flag.isLocalWebsite': window.location.hostname === 'localhost',
  'flag.isOldWebsite': window.location.hostname.endsWith('datasentinelai.com'),
  'flag.isProdWebsite': window.location.hostname === 'dashboard.sentinelinsights.com',
  'flag.isDevWebsite': window.location.hostname === 'dashboard-dev.sentinelinsights.com',
};
/* eslint-enable */

type ExperimentsContextType = {
  counter: number;
  store: StoreType;
  refresh: () => void;
  explicitlyEnabledExperiments: string[];
  loadExperiments: (experiments: Partial<StoreType>) => void;
  setExplicitlyEnabledExperiments: (experiments: string[]) => void;
  isExperimentEnabled: (experimentName: keyof StoreType) => boolean;
  setExperimentEnabled: (experimentName: keyof StoreType, enabled: boolean) => void;
};

const ExperimentsContext = React.createContext({} as ExperimentsContextType);

export function ExperimentsProvider(props: React.PropsWithChildren) {
  const didLoadStoredExperiments = React.useRef(false);

  const [explicitlyEnabledExperiments, setExplicitlyEnabledExperiments] = React.useState(getEnabledFeatureFlags());

  const [counter, setCounter] = React.useState(0);
  const refresh = useCallback(() => {
    setCounter((c) => c + 1);
  }, []);

  useEffect(() => {
    const _ = counter;
    if (globalExperimentsStore['user.isInternal'] && !didLoadStoredExperiments.current) {
      didLoadStoredExperiments.current = true;
      explicitlyEnabledExperiments.forEach((x) => {
        if (x in globalExperimentsStore) {
          globalExperimentsStore[x] = true;
        }
      });
      refresh();
    }
  }, [counter, explicitlyEnabledExperiments, refresh]);

  //eslint-disable-next-line
  window.experiments = JSON.parse(JSON.stringify(globalExperimentsStore));
  window.refreshProviders = refresh;

  const isExperimentEnabled = useCallback((experimentName: keyof StoreType) => {
    return globalExperimentsStore[experimentName] ?? false;
  }, []);

  const setExperimentEnabled = useCallback(
    (experimentName: keyof StoreType, enabled: boolean) => {
      if (explicitlyEnabledExperiments.indexOf(experimentName as string) === -1) {
        globalExperimentsStore[experimentName] = enabled;
        refresh();
      }
    },
    [explicitlyEnabledExperiments, refresh]
  );

  const loadExperiments = useCallback(
    (experiments: Partial<StoreType>) => {
      Object.assign(globalExperimentsStore, experiments);
      refresh();
    },
    [refresh]
  );

  const value: ExperimentsContextType = {
    counter,
    refresh,
    loadExperiments,
    isExperimentEnabled,
    setExperimentEnabled,
    explicitlyEnabledExperiments,
    store: globalExperimentsStore,
    setExplicitlyEnabledExperiments,
  };

  return <ExperimentsContext.Provider value={value} {...props} />;
}

export default function useExperiments() {
  const context = useContext(ExperimentsContext);
  return context;
}
