import { Stack } from '@mui/material';
import React from 'react';

import { PostfixOperandSchema, PostfixOptions, PreviousStatePredicate } from '../../types';

import { OperandAutocomplete } from './components/Autocomplete';
import { OperandRegex } from './components/Regex';
import { OperandSelect } from './components/Select';

export type PostfixOperandProps<T extends PostfixOptions> = {
  properties: T['properties'];
  value: PostfixOperandSchema<T>;
  onChange: PreviousStatePredicate<PostfixOperandSchema<T>>;
  compact?: boolean;
};

export function View<T extends PostfixOptions>(props: PostfixOperandProps<T>) {
  const { properties, value, compact } = props;
  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      sx={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& > *': {
          flexGrow: 1,
          flexShrink: 0,
        },
      }}
    >
      {properties.map((property, index) => {
        switch (property.inputType) {
          case 'select':
            return (
              <OperandSelect
                key={`${index}-${property.name}`}
                compact={compact}
                property={property}
                value={value}
                onChange={props.onChange}
              />
            );
          case 'autocomplete':
            return (
              <OperandAutocomplete
                compact={compact}
                key={`${index}-${property.name}`}
                property={property}
                value={value}
                onChange={props.onChange}
              />
            );
          case 'regex':
            return (
              <OperandRegex
                compact={compact}
                key={`${index}-${property.name}`}
                property={property}
                value={value}
                onChange={props.onChange}
              />
            );
          default:
            return null;
        }
      })}
    </Stack>
  );
}
