import {
  useDateAvailableRange,
  useNextGlobalSourceOfTruth,
  useNextPageSourceOfTruth,
  useNextPageUrls,
  useNextTagIds,
} from 'api';
import { ApiSourceOfTruth, ApiSourceOfTruthRule, TagId } from 'api/apiTypes';
import { ApiSourceOfTruthPageFilter } from 'api/apiTypes/sourceOfTruth/pageFilters';
import { useReportGuids } from 'api/hooks/useEnvGuid';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import { error, isErrored, isLoading, loading, withoutError } from 'api/utils';
import { MenuItem } from 'components/MenuItems';
import { Moment } from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SWRResponse } from 'swr';

import useNotify from 'hooks/useNotify';

export type Props = any;
export const scopes = {
  /*all: 'All Variables',*/
  global: 'Global Variables',
  page: 'Page Specific Variables',
} as const;

export function useLoader(): Partial<SWRResponse> {
  const dateMeta = useDateAvailableRange();
  return {
    isLoading: dateMeta.isLoading,
    error: dateMeta.error,
    data: null,
  };
}

const defaultFilters: ApiSourceOfTruthPageFilter[] = [
  {
    dimension: 'PAGE_URL',
    operator: 'NOT_EQUALS',
    value: '',
  },
];

export function useController(_props: Props) {
  const { envGuid } = useReportGuids();
  const { notify } = useNotify();
  const getPageSot = useNextPageSourceOfTruth(envGuid);
  const getGlobalSot = useNextGlobalSourceOfTruth(envGuid);
  const getPageUrls = useNextPageUrls(envGuid);

  const [scope, setScope] = useState<keyof typeof scopes>('global');
  const [tagId, setTagId] = useState<TagId | null>(null);

  const dateMeta = useDateAvailableRange();
  const _tags = useNextTagIds(envGuid, dateMeta.currentRange);

  const [sourceOfTruth, setSourceOfTruth] = useState<ApiSourceOfTruthRule[]>([]);
  const [isLoadingSourceOfTruth, setIsLoadingSourceOfTruth] = useState(false);

  const [edited, setEdited] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalRows, setTotalRows] = useState(0);

  const { sortedTags } = useMemo(() => {
    const sortedTags: MenuItem[] =
      (_tags.data ?? [])
        ?.map<MenuItem>((x) => ({
          key: x.tagId,
          value: x.tagId,
          label: x.tagName,
          url: getFaviconForTechnology(x.tagName),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

    return {
      sortedTags,
      tagNames:
        (_tags.data ?? [])?.reduce((acc, tag) => ((acc[tag.tagId] = tag.tagId), acc), {} as Record<string, string>) ??
        {},
    };
  }, [_tags]);

  const onTagChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTagId(e.target.value as TagId);
  };

  const _scopes: MenuItem[] = useMemo(
    () =>
      Object.entries(scopes).map<MenuItem>(([key, value]) => ({
        key,
        value: key,
        label: value,
      })),
    []
  );

  const [pageFilters, setPageFilters] = useState<ApiSourceOfTruthPageFilter[]>([]);
  const [pageUrls, setPageUrls] = useState<string[] | ApiError | ApiLoading>([]);

  const onScopeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setScope(e.target.value as keyof typeof scopes);
    setPageFilters([]);
  };

  const [pageUrlMatch, setPageUrlMatch] = useState('');
  const onPageUrlChanged = (_: any, v: string | null) => {
    setPageUrlMatch(v ?? '');
  };
  const [pageUrlOperator, setPageUrlOperator] = useState<ApiSourceOfTruthPageFilter['operator']>('EQUALS');
  const onPageOperatorChanged = () => {
    setPageUrlOperator((prev) => {
      const _new = prev === 'EQUALS' ? 'MATCHES_REGEX' : 'EQUALS';
      if (_new === 'EQUALS') {
        setPageUrlMatch('');
      }
      return _new;
    });
  };

  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(500);

  useEffect(() => {
    if (scope === 'page') {
      setPageFilters([
        {
          dimension: 'PAGE_URL',
          operator: pageUrlOperator,
          value: pageUrlMatch,
        },
      ]);
    } else {
      setPageFilters([]);
    }
  }, [pageUrlMatch, pageUrlOperator, scope]);

  const tagError = !tagId;
  const dateError = !dateMeta.currentRange.fromDate || !dateMeta.currentRange.toDate;

  const pageSotError = tagError || dateError || !pageUrlMatch;
  const globalSotError = tagError || dateError;

  const canSubmit = scope === 'page' ? !pageSotError : !globalSotError;

  const onSubmit = async (_page?: number) => {
    const currentPage = _page ?? page;
    if (!canSubmit) return;

    setEdited(false);
    setIsLoadingSourceOfTruth(true);

    try {
      let data: ApiSourceOfTruth | undefined;
      if (scope === 'page') {
        data = await getPageSot?.(
          tagId ?? ('' as TagId),
          pageFilters.length ? pageFilters : defaultFilters,
          currentPage + 1,
          pageLimit,
          dateMeta.currentRange
        );
      } else {
        data = await getGlobalSot?.(tagId ?? ('' as TagId), currentPage + 1, pageLimit, dateMeta.currentRange);
      }

      if (!data) throw new Error();

      const rows = data.rules
        ?.flatMap((x) => [
          {
            ...x,
            id: [scope, x.groupPath, x.pageUrl, ...x.types, x.values].join('-'),
            path: [`${x.groupPath}-${x.pageUrl}`],
          },
          ...(x.values.length > 1
            ? x.values.map((y, index) => ({
                ...x,
                values: [y],
                parent: x,
                id: [scope, x.groupPath, x.pageUrl, ...x.types, y, index].join('-'),
                path: [`${x.groupPath}-${x.pageUrl}`, `${y}-${index}`],
              }))
            : []),
        ])
        ?.sort((a, b) => a.groupPath.localeCompare(b.groupPath));

      setSourceOfTruth(rows);
      setTotalRows(data.totalRows ?? 0);
    } catch {
      setEdited(true);
      notify({
        message: 'Failed to fetch source of truth',
        severity: 'error',
      });
    }

    setIsLoadingSourceOfTruth(false);
  };

  const onPageChange = (v: number) => {
    setPage(v);
    onSubmit(v);
  };

  const onSubmitClick = () => onSubmit();

  const pageSearchTimeout = useRef<NodeJS.Timeout | null>(null);

  const onPageSearch = (_: any, v: string) => {
    if (pageUrlOperator === 'MATCHES_REGEX') return;
    setPageUrls(loading());
    if (pageSearchTimeout.current !== null) clearTimeout(pageSearchTimeout.current);
    if (!v || dateError) {
      setPageUrls(error());
      return;
    }
    pageSearchTimeout.current = setTimeout(async () => {
      if (dateMeta.currentRange.fromDate === null || dateMeta.currentRange.toDate === null) return;
      const data = await getPageUrls?.(
        tagId ?? ('' as TagId),
        dateMeta.currentRange.fromDate,
        dateMeta.currentRange.toDate,
        v
      );
      setPageUrls(data);
    }, 500);
  };

  useEffect(() => {
    const _ = [dateMeta.currentRange, tagId, scope, pageUrlMatch, pageUrlOperator];
    setEdited(true);
  }, [dateMeta.currentRange, pageUrlMatch, pageUrlOperator, scope, tagId]);

  const [, setSearchParams] = useSearchParams();

  return {
    date: {
      dateMeta,
      setFromDate: (v: Moment | null) =>
        setSearchParams((prev) => {
          if (v) {
            prev.set('fromDate', v.format('YYYY-MM-DD'));
          } else {
            prev.delete('fromDate');
          }
          return prev;
        }),
      setToDate: (v: Moment | null) =>
        setSearchParams((prev) => {
          if (v) {
            prev.set('toDate', v.format('YYYY-MM-DD'));
          } else {
            prev.delete('toDate');
          }
          return prev;
        }),
    },
    scope: {
      list: _scopes,
      current: scope ?? '',
      onChange: onScopeChange,
    },
    tags: {
      error: tagError,
      list: sortedTags,
      onChange: onTagChange,
      current: tagId ?? '',
      disabled: !sortedTags.length || dateError,
    },
    pageUrl: {
      current: pageUrlMatch,
      onSearch: onPageSearch,
      visible: scope === 'page',
      onChange: onPageUrlChanged,
      loading: isLoading(pageUrls),
      disabled: isErrored(pageUrls),
      list: withoutError(pageUrls) ?? [],
      noItemsText: dateError || tagError ? 'Please select a technology and a date' : 'No items found',
      isRegex: {
        current: pageUrlOperator === 'MATCHES_REGEX',
        onChange: onPageOperatorChanged,
      },
    },
    submit: {
      disabled: !(edited && canSubmit),
      onChange: onSubmitClick,
    },
    sourceOfTruth: {
      current: isLoadingSourceOfTruth ? [] : sourceOfTruth,
      loading: isLoadingSourceOfTruth,
      meta: {
        pages: {
          current: page,
          onChange: onPageChange,
        },
        rows: {
          total: totalRows,
          current: pageLimit,
          onChange: setPageLimit,
        },
      },
    },
  };
}

export function getFaviconForTechnology(technologyName: string, partial?: boolean) {
  if (!technologyName) return '';
  const technologyNameLower = technologyName.toLowerCase();
  let url = '';
  if (partial) {
    const keys = Object.keys(TechnologyNameOverrides);
    const partialMatch = keys.find((key) => key.includes(technologyNameLower) || technologyNameLower.includes(key));
    if (partialMatch) url = TechnologyNameOverrides[partialMatch];
  }
  url = TechnologyNameOverrides[technologyNameLower] ?? url ?? `https://${technologyNameLower}.com`;
  const directTranslation = TechnologyDirectTranslations[technologyNameLower];
  if (directTranslation) return directTranslation;
  return `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=32&url=${url}`;
}

/* eslint-disable @typescript-eslint/naming-convention */
const TechnologyDirectTranslations: Record<string, string> = {
  'data layer':
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='128px' height='128px' viewBox='0 0 128 128' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpath d='M128 0L128 0L128 128L0 128L0 0L128 0Z' id='path_1' /%3E%3Cpath d='M0 0L128 0L128 128L0 128L0 0Z' id='path_2' /%3E%3CclipPath id='clip_1'%3E%3Cuse xlink:href='%23path_1' clip-rule='evenodd' fill-rule='evenodd' /%3E%3C/clipPath%3E%3CclipPath id='clip_2'%3E%3Cuse xlink:href='%23path_2' clip-rule='evenodd' fill-rule='evenodd' transform='translate(0, -5.909092)' /%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Frame' clip-path='url(%23clip_1)'%3E%3Cpath d='M128 0L128 0L128 128L0 128L0 0L128 0Z' id='Frame' fill='none' stroke='none' /%3E%3Cg id='' clip-path='url(%23clip_2)' transform='translate(0 5.909092)'%3E%3Cg transform='translate(1.4090881, 0)' id='DL'%3E%3Cpath d='M31.3636 93L4.5 93L4.5 23.1818L31.0909 23.1818Q41.8636 23.1818 49.7216 27.358Q57.5795 31.5341 61.858 39.358Q66.1364 47.1818 66.1364 58.0909Q66.1364 69 61.875 76.8239Q57.6136 84.6477 49.8068 88.8239Q42 93 31.3636 93ZM23.4545 76.9091L30.6818 76.9091Q35.8636 76.9091 39.5284 75.2386Q43.1932 73.5682 45.1193 69.4773Q47.0455 65.3864 47.0455 58.0909Q47.0455 50.7955 45.0852 46.7045Q43.125 42.6136 39.358 40.9432Q35.5909 39.2727 30.1364 39.2727L23.4545 39.2727L23.4545 76.9091ZM74.8636 93L74.8636 23.1818L93.8182 23.1818L93.8182 77.7273L122.045 77.7273L122.045 93L74.8636 93Z' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E",
};
/* eslint-enable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/naming-convention */
const TechnologyNameOverrides: Record<string, string> = {
  addthis: 'https://www.addthis.com/assets/logo.svg',
  adobe: 'https://www.adobe.com',
  'adobe analytics': 'https://www.adobe.com',
  'adobe audience manager': 'https://www.adobe.com',
  'adobe dynamic tag management': 'https://www.adobe.com',
  'adobe launch': 'https://www.adobe.com',
  'adobe target': 'https://www.adobe.com',
  'adobe typekit': 'https://www.adobe.com',
  'advertising.com': 'https://advertising.com',
  'aggregate knowledge': 'https://www.home.neustar/',
  'azure application tracking': 'https://azure.microsoft.com',
  //'alex metrics': 'https://www.alexa.com',
  'amazon advertising': 'https://advertising.amazon.com',
  'answers cloud': 'https://www.foresee.com',
  appnexus: 'https://console.appnexus.com',
  'bing ads': 'https://ads.microsoft.com',
  'blue triangle': 'https://www.bluetriangle.com',
  'bounce exchange': 'https://www.wunderkind.co',
  'c3 metrics': 'https://c3metrics.com',
  'crazy egg': 'https://www.crazyegg.com',
  'cross pixel': 'http://crosspixel.net',
  dataxu: 'https://undefined',
  'dc stormiq': 'http://stormiq.co',
  'decibel insight': 'https://www.medallia.com/',
  drawbridge: 'https://drawbridgeco.com',
  'edmunds - edw': 'https://www.edmunds.com',
  'ensighten - tag management': 'https://manage.ensighten.com/',
  evidon: 'https://www.crownpeak.com',
  exelate: 'https://www.nielsen.com/',
  'facebook connect': 'https://www.facebook.com',
  'facebook social plugin': 'https://www.facebook.com',
  'facebook subscribe': 'https://www.facebook.com',
  'facebook - privacy sandbox': 'https://www.facebook.com',
  'glassbox digital': 'https://www.glassbox.com',
  google: 'https://www.google.com',
  'google adsense': 'https://www.google.com/adsense',
  'google adwords': 'https://ads.google.com/home/',
  'google analytics - legacy': 'https://analytics.google.com',
  'google analytics 4': 'https://analytics.google.com',
  'google audiences': 'https://ads.google.com/home/',
  'google floodlight/doubleclick': 'https://ads.google.com/home/',
  'google global site tag': 'https://ads.google.com/home/',
  'google optimize': 'https://optimize.google.com',
  'google publisher': 'https://ads.google.com/home/',
  'google safeframe': 'https://ads.google.com/home/',
  'google tag manager': 'https://tagmanager.google.com',
  'google universal analytics': 'https://analytics.google.com',
  'google apis': 'https://www.google.com',
  'google doubleclick digital marketing (ddm)': 'https://www.google.com',
  'google global site destination tag': 'https://analytics.google.com',
  'google maps': 'https://maps.google.com',
  'google recaptcha': 'https://www.google.com/',
  heap: 'https://heap.io',
  'hot jar': 'https://www.hotjar.com',
  igodigital: 'https://www.salesforce.com/',
  impact: 'https://www.impactanalytics.co',
  'improve digital': 'https://www.improvedigital.com',
  'index exchange': 'https://www.indexexchange.com',
  'iovation - reputationshield': 'https://www.transunion.com',
  iperceptions: 'https://emplifi.io',
  'krux digital': 'https://www.salesforce.com/',
  linkedin: 'https://www.linkedin.com',
  livefyre: 'http://livefyre.de',
  'marketo web activity': 'https://www.marketo.com',
  'media math': 'https://www.mediamath.com',
  'media.net': 'https://www.media.net',
  'medallia - base tag': 'https://www.medallia.com/',
  'merkle research': 'https://www.merkle.com',
  mpulse: 'https://www.akamai.com',
  'neustar adadvisor': 'https://www.home.neustar/',
  'new relic': 'https://newrelic.com',
  'oracle infinty': 'https://www.oracle.com',
  'power reviews': 'https://www.powerreviews.com',
  'quantum metric': 'https://www.quantummetric.com',
  'radium one': 'https://www.radiumone.com',
  'resonate networks': 'https://www.resonate.com',
  'rocket fuel': 'https://www.rocketfuel.com',
  'salesforce - dmp': 'https://www.salesforce.com/',
  samba: 'https://www.samba.tv',
  'siteimprove analytics': 'https://siteimprove.com',
  'snapchat for business': 'https://business.snapchat.com',
  snatchbot: 'https://snatchbot.me',
  'sojern travel marketing platform': 'https://www.sojern.com',
  sortable: 'https://undefined',
  'speedshift media': 'https://www.speedshiftmedia.com',
  stickyads: 'https://undefined',
  storify: 'https://storify.com',
  'tealium - audiencestream': 'https://tealium.com',
  'tealium - tag management': 'https://tealium.com',
  'trade desk': 'https://www.thetradedesk.com',
  'tribal fusion': 'https://www.exponential.com',
  'turn inc': 'https://www.nexxen.com',
  'twitter - advertising': 'https://ads.twitter.com',
  'twitter - analytics': 'https://analytics.twitter.com',
  'twitter - widget': 'https://twitter.com',
  'visual website optimizer': 'https://vwo.com',
  visualiq: 'https://www.nielsen.com/',
  'yahoo ad exchange': 'https://www.yahoo.com',
  'yahoo analytics': 'https://www.yahoo.com',
  'yahoo dot': 'https://www.yahoo.com',
  'youtube video': 'https://www.youtube.com',
};
/* eslint-enable */
