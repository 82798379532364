import { Stack } from '@mui/material';
import React from 'react';
import { Stateful } from 'utils';

import { PostfixGroup } from './components';
import { PostfixGroupSchema, PostfixOptions } from './types';

// Postfix New is the main component handling postfix logic in the frontend
// The "New" suffix is added because there is a legacy version of the component
// which was used in the feedback filter rules (outdated postfix).

// This postfix component includes many things such as:
// - custom schema
// - advanced row and value based logic
// - custom components for each type of postfix operand
// - input validation
// - tons of performance improvements
// - true postfix style nesting

/**
 * Allowed props that can be passed to the Postfix component
 */
export type PostfixNewProps<T extends PostfixOptions> = {
  /**
   * Required, the current postfix "schema".
   * This object exposes fields from the `value` prop to be displayed with the specified options.
   */
  options: T;

  /**
   * Required, the arbitrary "value" of the postfix schema.
   */
  value: PostfixGroupSchema<T>;

  /**
   * Required, the function to call when the value changes.
   */
  onChange: Stateful<PostfixGroupSchema<T>>;

  /**
   * Optional, whether to display the postfix in a compact mode.
   */
  compact?: boolean;
};

// To follow the standard created before, where each component is broken down into a view and controller file,
// this fle is exported as View. This allows for the parent component (the exposing component) to perform prop validation
// along with error handling.

export default function View<T extends PostfixOptions>(props: PostfixNewProps<T>) {
  const { value, onChange, compact, options } = props;
  return (
    <Stack>
      <PostfixGroup depth={1} value={value} onChange={onChange} options={options} compact={compact} />
    </Stack>
  );
}
