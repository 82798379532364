import { MenuItem } from 'components/MenuItems';

export const attributes = {
  value: { friendlyName: 'Value', type: ['all'], outType: 'all' },
  type: { friendlyName: 'Type', type: ['any'], outType: 'string' },
  anomaly: {
    friendlyName: 'Anomaly',
    type: [''],
    outType: 'exists',
  },
  pii: {
    friendlyName: 'PII',
    type: [''],
    outType: 'exists',
  },
  bp_violation: {
    friendlyName: 'Best Practice Violation',
    type: [''],
    outType: 'exists',
  },
  length: { friendlyName: 'Length', type: ['string', 'array'], outType: 'number' },
  consent_violation: {
    friendlyName: 'Consent Violation',
    type: [''],
    outType: 'exists',
  },
};

export const anomalyAttributes: MenuItem = {
  key: 'anomaly',
  label: attributes.anomaly.friendlyName,
  value: 'anomaly',
};

export const piiAttributes: MenuItem = {
  key: 'pii',
  label: attributes.pii.friendlyName,
  value: 'pii',
};

export const attributesList = Object.entries(attributes)
  .map(([key, value]) => ({
    ...value,
    id: key,
  }))
  .sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));

export const attributesMenuItems: MenuItem[] = attributesList
  .filter((x) => x.type.includes('all') || x.type.includes('any'))
  .map((type) => ({
    key: type.id,
    label: type.friendlyName,
    value: type.id,
  }));

export const attributesStringMenuItems: MenuItem[] = attributesList
  .filter((x) => x.type.includes('string') || x.type.includes('all'))
  .map((type) => ({
    key: type.id,
    label: type.friendlyName,
    value: type.id,
  }));

export const attributesNumberMenuItems: MenuItem[] = attributesList
  .filter((x) => x.type.includes('number') || x.type.includes('all'))
  .map((type) => ({
    key: type.id,
    label: type.friendlyName,
    value: type.id,
  }));

export const attributesBooleanMenuItems: MenuItem[] = attributesList
  .filter((x) => x.type.includes('boolean') || x.type.includes('all'))
  .map((type) => ({
    key: type.id,
    label: type.friendlyName,
    value: type.id,
  }));

export const attributesStringAndArrayMenuItems: MenuItem[] = attributesList
  .filter((x) => x.type.includes('string') || x.type.includes('array') || x.type.includes('all'))
  .map((type) => ({
    key: type.id,
    label: type.friendlyName,
    value: type.id,
  }));

export const types = {
  null: { friendlyName: 'Null' },
  array: { friendlyName: 'Array' },
  date: { friendlyName: 'Date' },
  regex: { friendlyName: 'Regex' },
  undefined: { friendlyName: 'Undefined' },
  boolean: { friendlyName: 'Boolean' },
  number: { friendlyName: 'Number' },
  string: { friendlyName: 'String' },
  symbol: { friendlyName: 'Symbol' },
  object: { friendlyName: 'Object' },
  function: { friendlyName: 'Function' },
};

export const typesKeys = Object.keys(types);

export const typesList = Object.entries(types)
  .map(([key, value]) => ({
    ...value,
    id: key,
  }))
  .sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));

/*export const typesMenuItems: MenuItem[] = typesList.map((type) => ({
  key: type.id,
  label: type.friendlyName,
  value: type.id,
}));*/

export const booleanValues = {
  true: { friendlyName: 'True' },
  false: { friendlyName: 'False' },
};

export const booleanValuesKeys = Object.keys(booleanValues);

/*export const booleanMenuItems: MenuItem[] = [
  {
    key: 'true',
    label: 'True',
    value: 'true',
  },
  {
    key: 'false',
    label: 'False',
    value: 'false',
  },
];*/

export const comparators = {
  equals: {
    friendlyName: 'Equals',
    supportedTypes: ['all', 'equalsOnly', 'attribute_type', 'attribute_length'],
    negative: false,
    hideValue: false,
    array: false,
  },
  doesNotEqual: {
    friendlyName: 'Does not equal',
    supportedTypes: ['all', 'attribute_type', 'attribute_length'],
    negative: true,
    hideValue: false,
    array: false,
  },
  contains: {
    friendlyName: 'Contains',
    supportedTypes: ['string'],
    negative: false,
    hideValue: false,
    array: false,
  },
  doesNotContain: {
    friendlyName: 'Does not contain',
    supportedTypes: ['string'],
    negative: true,
    hideValue: false,
    array: false,
  },
  startsWith: {
    friendlyName: 'Starts with',
    supportedTypes: ['string', 'number'],
    negative: false,
    hideValue: false,
    array: false,
  },
  doesNotStartWith: {
    friendlyName: 'Does not start with',
    supportedTypes: ['string', 'number'],
    negative: true,
    hideValue: false,
    array: false,
  },
  endsWith: {
    friendlyName: 'Ends with',
    supportedTypes: ['string', 'number'],
    negative: false,
    hideValue: false,
    array: false,
  },
  doesNotEndWith: {
    friendlyName: 'Does not end with',
    supportedTypes: ['string', 'number'],
    negative: true,
    hideValue: false,
    array: false,
  },
  isTruthy: {
    friendlyName: 'Is truthy',
    supportedTypes: ['all'],
    negative: false,
    hideValue: true,
    array: false,
  },
  isFalsy: {
    friendlyName: 'Is falsy',
    supportedTypes: ['all'],
    negative: false,
    hideValue: true,
    array: false,
  },
  exists: {
    friendlyName: 'Exists',
    supportedTypes: ['all', 'exists'],
    negative: false,
    hideValue: true,
    array: false,
  },
  doesNotExist: {
    friendlyName: 'Does not exist',
    supportedTypes: ['all', 'exists'],
    negative: true,
    hideValue: true,
    array: false,
  },
  isNull: {
    friendlyName: 'Is null',
    supportedTypes: ['all'],
    negative: false,
    hideValue: true,
    array: false,
  },
  isNotNull: {
    friendlyName: 'Is not null',
    supportedTypes: ['all'],
    negative: true,
    hideValue: true,
    array: false,
  },
  isOneOf: {
    friendlyName: 'Is one of',
    supportedTypes: ['all', 'equalsOnly', 'attribute_type'],
    negative: false,
    hideValue: false,
    array: true,
  },
  isNotOneOf: {
    friendlyName: 'Is not one of',
    supportedTypes: ['all', 'attribute_type'],
    negative: true,
    hideValue: false,
    array: true,
  },
  lessThan: {
    friendlyName: 'Less than',
    supportedTypes: ['number', 'attribute_length'],
    negative: false,
    hideValue: false,
    array: false,
  },
  lessThanOrEquals: {
    friendlyName: 'Less than or equals',
    supportedTypes: ['number', 'attribute_length'],
    negative: false,
    hideValue: false,
    array: false,
  },
  greaterThan: {
    friendlyName: 'Greater than',
    supportedTypes: ['number', 'attribute_length'],
    negative: false,
    hideValue: false,
    array: false,
  },
  greaterThanOrEquals: {
    friendlyName: 'Greater than or equals',
    supportedTypes: ['number', 'attribute_length'],
    negative: false,
    hideValue: false,
    array: false,
  },
  isTrue: {
    friendlyName: 'Is true',
    supportedTypes: ['boolean'],
    negative: false,
    hideValue: true,
    array: false,
  },
  isFalse: {
    friendlyName: 'Is false',
    supportedTypes: ['boolean'],
    negative: false,
    hideValue: true,
    array: false,
  },
  matchesRegex: {
    friendlyName: 'Matches regex',
    supportedTypes: ['string', 'number'],
    negative: false,
    hideValue: false,
    array: false,
  },
  doesNotMatchRegex: {
    friendlyName: 'Does not match regex',
    supportedTypes: ['string', 'number'],
    negative: true,
    hideValue: false,
    array: false,
  },
};

export const comparatorsList = Object.entries(comparators)
  .map(([key, value]) => ({
    ...value,
    id: key,
  }))
  .sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));

export const comparatorMenuItems: MenuItem[] = comparatorsList
  .filter((x) => x.supportedTypes.includes('all'))
  .map((comparator) => ({
    key: comparator.id,
    label: comparator.friendlyName,
    value: comparator.id,
  }));

export const comparatorStringMenuItems: MenuItem[] = comparatorsList
  .filter((x) => x.supportedTypes.includes('string') || x.supportedTypes.includes('all'))
  .map((comparator) => ({
    key: comparator.id,
    label: comparator.friendlyName,
    value: comparator.id,
  }));

export const comparatorNumberMenuItems: MenuItem[] = comparatorsList
  .filter((x) => x.supportedTypes.includes('number') || x.supportedTypes.includes('all'))
  .map((comparator) => ({
    key: comparator.id,
    label: comparator.friendlyName,
    value: comparator.id,
  }));

export const comparatorBooleanMenuItems: MenuItem[] = comparatorsList
  .filter((x) => x.supportedTypes.includes('boolean'))
  .map((comparator) => ({
    key: comparator.id,
    label: comparator.friendlyName,
    value: comparator.id,
  }));

export const comparatorExistsMenuItems: MenuItem[] = comparatorsList
  .filter((x) => x.supportedTypes.includes('exists'))
  .map((comparator) => ({
    key: comparator.id,
    label: comparator.friendlyName,
    value: comparator.id,
  }));
export const comparatorEqualsOnlyMenuItems: MenuItem[] = comparatorsList
  .filter((x) => x.supportedTypes.includes('equalsOnly'))
  .map((comparator) => ({
    key: comparator.id,
    label: comparator.friendlyName,
    value: comparator.id,
  }));
